import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { notification, Typography } from 'antd';

import * as md5 from 'md5';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { FormInput, Table } from '../../../components';
import { authorizedPOST, authorizedPUT, authorizedGET } from '../../../helpers/requester';
import { user } from '../../../constants/endpoints';

const defaultCreateUser = {
  firstName: null,
  emailAddress: '',
  role: 'user',
};

function CompaniesPage() {
  const userInfo = useSelector((state) => state.login);

  const [state, setState] = useState({
    createUser: defaultCreateUser,
    campaignsList: {
      campaigns: [],
      name: null,
    },
  });

  const findCampaign = async (name) => {
    try {
      const campaigns = await authorizedGET(`${user.root}?offset=0&limit=5&name=${name}`);

      setState({
        ...state,
        campaignsList: {
          ...state.campaignsList,
          campaigns,
        },
      });
    } catch (e) {
      notification.error({ message: `Error when find a company: ${e}` });
    }
  };

  const onInputChange = (subState) => (name) => (event) => {
    setState({
      ...state,
      [subState]: {
        ...state[subState],
        [name]: event.target.value,
      },
    });
  };

  const userOnInputChange = onInputChange('createUser');
  const campaignsListOnInputChange = onInputChange('campaignsList');

  // TODO: Use schema validation instead
  const validateCampaign = ({ userObj }) => {
    console.log(userObj);
    const emptyFieldName = Object.keys(_.omit(userObj, ['twoFaSecret', 'twoFaStatus'])).find((key) =>
      [null, undefined, ''].includes(userObj[key])
    );

    if (emptyFieldName) {
      console.log(userObj);
      notification.info({
        message: `The field "${emptyFieldName}" is required (${userObj[emptyFieldName]}). Please, fill it`,
      });

      return false;
    }

    return true;
  };

  const createUser = async () => {
    const isValid = validateCampaign({ userObj: state.createUser });

    if (!isValid) return;

    try {
      await authorizedPOST(user.root, {
        ...state.createUser,
        lastName: state.createUser.firstName,
        exchangeName: userInfo.exchangeName,
        login: `${userInfo.exchangeName}_${state.createUser.firstName.replace(/\W/g, '_')}`,
        password: md5(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)),
      });

      setState({
        ...state,
        createUser: defaultCreateUser,
      });

      notification.success({ message: 'Company created' });
    } catch (e) {
      notification.error({ message: `Error when creating a Company: ${e}` });
    }
  };

  const updateUser = async () => {
    const isValid = validateCampaign({ userObj: state.createUser });

    if (!isValid) return;
    if (!state.createUser.id) {
      notification.error({ message: 'First of all you need to select Company' });
      return;
    }

    try {
      await authorizedPUT(`${user.root}/${state.createUser.id}`, {
        ...state.createUser,
        lastName: state.createUser.firstName,
        exchangeName: userInfo.exchangeName,
      });

      setState({
        ...state,
        createUser: defaultCreateUser,
      });

      notification.success({ message: 'Company updated' });
    } catch (e) {
      notification.error({ message: `Error when updating a Company: ${e}` });
    }
  };

  const selectCompanyForEdit = async (data) => {
    setState({
      ...state,
      createUser: data,
    });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Typography.Text strong={true}>Find Company</Typography.Text>

            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await findCampaign(state.campaignsList.name);
              }}
            >
              <FormInput
                type="text"
                placeholder="Enter Company name"
                onChange={campaignsListOnInputChange('name')}
                value={state.campaignsList.name || ''}
                label="Name"
                name="name"
              />

              <Button variant="primary" type="submit">
                Find Company
              </Button>
            </Form>

            <Table
              data={state.campaignsList.campaigns || []}
              selectKey="id"
              onSelectFull
              onSelect={selectCompanyForEdit}
            />
          </div>
          <div className="col-md-6">
            <Typography.Text strong={true}>Create Company</Typography.Text>

            <Form onSubmit={(e) => e.preventDefault()}>
              <FormInput
                type="text"
                placeholder="Enter Company Name"
                onChange={userOnInputChange('firstName')}
                value={state.createUser.firstName || ''}
                label="Company Name"
                name="firstName"
              />

              <FormInput
                type="email"
                placeholder="Enter email"
                onChange={userOnInputChange('emailAddress')}
                value={state.createUser.emailAddress || ''}
                name="email"
                label="Email"
              />

              <Button variant="primary" onClick={createUser}>
                Create Company
              </Button>

              <Button variant="primary" onClick={updateUser}>
                Update Company
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompaniesPage;
