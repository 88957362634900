import React from 'react';

import DataSampleTool from './components/DataSampleTool';
import { SwapperBulkManager } from './components/SwapperBulkManager';
import { Collapse } from 'antd';
import { useSelector } from 'react-redux';

export default function Tools() {
  const userInfo = useSelector((state) => state.login);

  return (
    <Collapse bordered={false} style={{ backgroundColor: 'transparent' }}>
      <Collapse.Panel header="Data Sample Tool" key="0">
        <DataSampleTool />
      </Collapse.Panel>

      {userInfo && ['admin', 'localAdmin'].includes(userInfo.role) && (
        <Collapse.Panel header="Swapper Bulk Manager" key="1">
          <SwapperBulkManager />
        </Collapse.Panel>
      )}
    </Collapse>
  );
}
