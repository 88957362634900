import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Button, Input, Typography } from 'antd';

import { createApiGateway } from '../../../apiGateway';

import UpdatePassword from './components/updatePassword';

export default function Settings() {
  const userInfo = useSelector((state) => state.login);
  const apiGateway = createApiGateway();

  const [qrCode, setQrCode] = useState(null);
  const [secret, setSecret] = useState(null);
  const [otp, setOtp] = useState(null);

  const configure2fa = async () => {
    const twoFaInfo = await apiGateway.users.configure2fa(userInfo.userId);
    setQrCode(twoFaInfo.data.qrCode);
    setSecret(twoFaInfo.data.secret);
  };

  const enable2fa = async () => {
    await apiGateway.users.enable2fa(userInfo.userId);
    window.location.reload();
  };

  const disable2fa = async () => {
    try {
      await apiGateway.users.disable2fa(userInfo.userId, otp);
      window.location.reload();
    } catch (e) {
      alert('Incorrect OTP');
    }
  };

  const otpChange = (e) => {
    setOtp(e.target.value || '');
  };

  return (
    <Container>
      <Row style={{ flexDirection: 'column' }}>
        {(userInfo && userInfo.twoFaSecret && userInfo.twoFaStatus && (
          <Col md={6}>
            <Typography.Text strong={true}>Two Factor Authentication</Typography.Text>

            <br />

            <Input placeholder="One Time Password (OTP)" onChange={otpChange} value={otp} />
            <br />
            <br />
            <Button type="primary" onClick={disable2fa}>
              Disable
            </Button>
          </Col>
        )) ||
          ''}

        {(userInfo && (!userInfo.twoFaSecret || !userInfo.twoFaStatus) && (
          <Col md={6}>
            <Typography.Text strong={true}>Two Factor Authentication</Typography.Text>
            <br />
            <Button type="primary" onClick={configure2fa}>
              Setup 2FA
            </Button>
            {(qrCode && (
              <div>
                <br />
                <ul>
                  <li>Download Google Authenticator</li>
                  <li>Scan QR code</li>
                  <li>Click Enable 2FA button to confirm that you added QR code to Google Authenticator</li>
                  <li>Log out and try to log in with OTP</li>
                </ul>
                <div>
                  <img src={qrCode} alt="QR Code" />
                  <p>
                    Or enter code manually:
                    {secret}
                  </p>
                </div>
                <Button type="primary" onClick={enable2fa}>
                  Enable 2FA
                </Button>
              </div>
            )) ||
              ''}
          </Col>
        )) ||
          ''}
      </Row>
      <br />
      <br />
      <Row>
        <Col md={6}>
          <UpdatePassword />
        </Col>
      </Row>
    </Container>
  );
}
