import axios from 'axios';
import getOr from './getOr';

const processResponse = (axiosResponse) => {
  const result = {};

  result.data = getOr(axiosResponse, 'data.data', getOr(axiosResponse, 'data', null));
  result.meta = getOr(axiosResponse, 'data.meta', 0);

  if (!result.meta) {
    return getOr(axiosResponse, 'data', null);
  }

  return result;
};

const prepareToken = () => `Bearer ${window.localStorage.getItem('accessToken')}`;

const clearSession = () => {
  window.localStorage.removeItem('accessToken');
  window.location = '/';
};

export const authorizedGET = async (url, headers = {}) => {
  try {
    headers.Authorization = prepareToken();
    headers['X-Version'] = 'v2';
    if (url.includes('[v1]')) {
      headers['X-Version'] = 'v1';
      url = url.replace('[v1]', '');
    }
    const response = await axios.get(url, { headers });
    return processResponse(response);
  } catch (e) {
    if (e.response && e.response.status === 401) return clearSession();
    if (e.response && e.response.status === 404) return [];
    throw e;
  }
};

export const paginatedGET = async (url, headers = {}) => {
  try {
    headers.Authorization = prepareToken();
    headers['X-Version'] = 'v2';

    if (url.includes('[v1]')) {
      headers['X-Version'] = 'v1';
      url = url.replace('[v1]', '');
    }

    const response = await axios.get(url, { headers });

    const parsedResponse = processResponse(response);
    if (parsedResponse.meta) {
      return parsedResponse;
    }
    return {
      data: parsedResponse,
      cursor: response.headers['x-next-cursor'],
    };
  } catch (e) {
    if (e.response.status === 401) return clearSession();
    throw e;
  }
};

export const authorizedPOST = async (url, data, headers = {}) => {
  try {
    headers.Authorization = prepareToken();
    const response = await axios.post(url, data, { headers });
    return processResponse(response);
  } catch (e) {
    if (e.response.status === 401) return clearSession();
    throw e;
  }
};

export const authorizedPUT = async (url, data, headers = {}) => {
  try {
    headers.Authorization = prepareToken();
    const response = await axios.put(url, data, { headers });
    return processResponse(response);
  } catch (e) {
    if (e.response.status === 401) return clearSession();
    throw e;
  }
};

export const authorizedDELETE = async (url, headers = {}) => {
  try {
    headers.Authorization = prepareToken();
    const response = await axios.delete(url, { headers });
    return processResponse(response);
  } catch (e) {
    if (e.response.status === 401) return clearSession();
    throw e;
  }
};
