import { useEffect, useState } from 'react';
import { createApiGateway } from '../apiGateway';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { notification } from 'antd';

dayjs.extend(utc);

const apiGateway = createApiGateway();

type Dsp = {
  id: number;
  name: string;
};

type User = {
  id: number;
};

export function useDsps() {
  const [dsps, setDsps] = useState<Dsp[]>([]);

  // @ts-expect-error TODO: fix this
  const getLastHourStatistics = async (hoursToSubtract = 1) => {
    const from = dayjs.utc().subtract(hoursToSubtract, 'hour').format('YYYY-MM-DD HH:00:00');
    const to = dayjs.utc().format('YYYY-MM-DD HH:00:00');

    const response = await apiGateway.auctionStatistics.listStatisticsByTable({
      table: 'hourlyStatistics.auctionByHour',
      from,
      to,
      dimensions: ['dspId', 'dateYMDH'],
    });

    if (response && response.rows && response.rows.length) {
      notification.info({
        message: `Statistics loaded for ${from} UTC`,
        duration: 15,
      });

      return response;
    }

    notification.info({
      message: `No data for ${from} UTC, subtracting one hour... Refresh the page to try this time again`,
      duration: 10,
    });

    return getLastHourStatistics(hoursToSubtract + 1);
  };

  useEffect(() => {
    (async () => {
      const [dspsResponse, usersResponse, lastHourStatisticsResponse] = await Promise.all([
        apiGateway.dsps.getAll(),
        apiGateway.users.getAll(),
        getLastHourStatistics(),
      ]);

      // @ts-expect-error TODO: fix this
      const dspsToSet = dspsResponse.map((dsp) => {
        const company = usersResponse.find((u: User) => u.id === dsp.partnerId) || null;

        return {
          ...dsp,
          company,
          // @ts-expect-error TODO: fix this
          lastHourStatistics: lastHourStatisticsResponse.rows.find((x) => String(x.dspId).includes(`[${dsp.id}]`)),
        };
      });

      setDsps(dspsToSet);
    })();
  }, []);

  return dsps;
}
