import React, { type ReactElement, useEffect, useState } from 'react';
import { Button, Modal, Checkbox, Flex } from 'antd';

type CheckboxValueType = string;

export type Props = {
  title: string;
  options: { label: string; value: CheckboxValueType }[];
  handleCheckboxChange: (value: CheckboxValueType[]) => void;
  value?: CheckboxValueType[];
};

export function ButtonWithSelect({ title, options, handleCheckboxChange, value }: Props): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checkedCheckboxes, setCheckedCheckboxes] = useState<CheckboxValueType[]>(value || []);

  useEffect(() => {
    setCheckedCheckboxes(value || []);
  }, [value]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handleCheckboxChange(checkedCheckboxes);

    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setCheckedCheckboxes(value || []);

    setIsModalOpen(false);
  };

  const handleUnselectAll = () => {
    setCheckedCheckboxes([]);
  };

  return (
    <>
      <Button onClick={showModal}>{title}</Button>

      <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Flex vertical={true} gap={'small'}>
          <Button onClick={handleUnselectAll} type="dashed">
            Unselect All
          </Button>

          <Checkbox.Group
            style={{ flexDirection: 'column' }}
            options={options.sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              }

              if (a.label > b.label) {
                return 1;
              }

              return 0;
            })}
            onChange={setCheckedCheckboxes}
            value={checkedCheckboxes}
          />
        </Flex>
      </Modal>
    </>
  );
}
