import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import numeral from 'numeral';

export function TableSummary({ data: rawData, dateTimeUnit }) {
  const data = rawData.map((x) => ({
    ...rawData,
    sspBidResponseBid: numeral(x.sspBidResponseBid).value(),
    wins: numeral(x.wins).value(),
    impressions: numeral(x.impressions).value(),
    price: numeral(x.price).value(),
    dspBidResPrice: numeral(x.dspBidResPrice).value(),
  }));

  return (
    <Table.Summary.Row style={{ fontWeight: 'bold' }}>
      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>

      {dateTimeUnit !== 'whole' && <Table.Summary.Cell index={1} />}
      <Table.Summary.Cell index={2} />

      <Table.Summary.Cell index={3}>{_.round(_.sum(data.map((x) => x.sspBidResponseBid)), 3)}</Table.Summary.Cell>

      <Table.Summary.Cell index={4}>{_.round(_.sum(data.map((x) => x.wins)), 3)}</Table.Summary.Cell>

      <Table.Summary.Cell index={5}>{_.round(_.sum(data.map((x) => x.impressions)), 3)}</Table.Summary.Cell>

      <Table.Summary.Cell index={6}>{_.round(_.sum(data.map((x) => x.price)), 3)}</Table.Summary.Cell>

      <Table.Summary.Cell index={7}>{_.round(_.sum(data.map((x) => x.dspBidResPrice)), 3)}</Table.Summary.Cell>

      <Table.Summary.Cell index={8}>
        {_.round(_.sum(data.map((x) => x.dspBidResPrice - x.price)), 3)}
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
}

TableSummary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      sspBidResponseBid: PropTypes.number.isRequired,
      wins: PropTypes.number.isRequired,
      impressions: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      dspBidResPrice: PropTypes.number.isRequired,
    })
  ).isRequired,
  dateTimeUnit: PropTypes.string.isRequired,
};
