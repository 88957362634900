import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import { Table, Form, Radio, Button, Popconfirm, notification, Drawer, Select, Typography, Input, Spin } from 'antd';
import { useSelector } from 'react-redux';

import useApiGateway from '../../../apiGateway';
import getOr from '../../../helpers/getOr';

export default function userManagement() {
  const apiGateway = useApiGateway();

  const [exchangeFilter, setExchangeFilter] = useState('all');
  const [dataSource, setDataSource] = useState([]);
  const [tableData, setTableData] = useState(dataSource);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editableUser, setEditableUser] = useState(null);
  const [userAccessDrawerVisible, setUserAccessDrawerVisible] = useState(null);
  const [dsps, setDsps] = useState(null);
  const [ssps, setSsps] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [userAccesses, setUserAccesses] = useState(null);
  const [userAccessUser, setUserAccessUser] = useState(null);
  const [userAccessSpinning, setUserAccessSpinning] = useState(false);

  const userInfo = useSelector((state) => state.login);

  const getData = async () => {
    const users = await apiGateway.users.getAll({
      allowedRoles:
        userInfo.role === 'admin' ? ['superUser', 'superUserLimited', 'localAdmin'] : ['superUser', 'superUserLimited'],
    });
    const dspsResponse = await apiGateway.dsps.getAll();
    const sspsResponse = await apiGateway.ssps.getAll();
    const companiesResponse = await apiGateway.users.getAll({
      allowedRoles: ['user'],
    });

    setCompanies(companiesResponse);
    setDsps(dspsResponse);
    setSsps(sspsResponse);
    setDataSource(users);
    setTableData(users);
  };

  useEffect(() => {
    (async () => {
      await getData();

      console.log(`editableUser: ${editableUser}`);
    })();
  }, []);

  // It will update data in table based on exchange name filter
  // If exchange name is equal to "none" then all data will be displayed
  useEffect(() => {
    if (exchangeFilter && tableData && tableData.length) {
      const updatedTableData = dataSource.filter(({ exchangeName }) =>
        exchangeFilter === 'all' ? true : exchangeName === exchangeFilter
      );
      setTableData(updatedTableData);
    }
  }, [exchangeFilter, dataSource]);

  const createNewUserDrawer = () => {
    setEditableUser({
      firstName: null,
      lastName: null,
      emailAddress: null,
      login: null,
      exchangeName: 'waardex',
      role: 'superUser',
      permission: 'create',
      password: null,
    });
    setDrawerVisible(true);
  };

  const useTable = () => {
    const deleteUser = async (user) => {
      const userId = user.id;

      try {
        await apiGateway.users.deleteUser(userId);

        notification.success({
          message: 'User deleted',
          description: `User with email ${user.emailAddress} was successfully deleted`,
        });
      } catch (e) {
        notification.error({
          message: 'Error when deleting user',
          description: e.message,
        });
      } finally {
        await getData();
      }
    };

    const editUserAccess = async (user) => {
      let userAccessesResponse = await apiGateway.users.getUserAccess(user.id);
      userAccessesResponse = getOr(userAccessesResponse, 'data', []);

      userAccessesResponse = {
        original: {
          dsps: _(userAccessesResponse)
            .filter((x) => x.dspId)
            .filter(_.identity)
            .uniq()
            .value(),
          ssps: _(userAccessesResponse)
            .filter((x) => x.sspId)
            .filter(_.identity)
            .uniq()
            .value(),
          companies: _(userAccessesResponse)
            .filter((x) => x.companyId)
            .filter(_.identity)
            .uniq()
            .value(),
        },
        dsps: _(userAccessesResponse)
          .map((x) => x.dspId)
          .filter(_.identity)
          .uniq()
          .value(),
        ssps: _(userAccessesResponse)
          .map((x) => x.sspId)
          .filter(_.identity)
          .uniq()
          .value(),
        companies: _(userAccessesResponse)
          .map((x) => x.companyId)
          .filter(_.identity)
          .uniq()
          .value(),
      };
      setUserAccesses(userAccessesResponse);
      setUserAccessDrawerVisible(true);
      setUserAccessUser(user);
    };

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'Email',
        dataIndex: 'emailAddress',
      },
      {
        title: 'Login',
        dataIndex: 'login',
      },
      {
        title: 'Role',
        render: (user) => {
          if (user.role === 'superUserLimited') {
            return (
              <Button type="link" onClick={() => editUserAccess(user)} style={{ margin: 0, padding: 0 }}>
                Limited Manager
              </Button>
            );
          }

          if (user.role === 'superUser') {
            return 'Manager';
          }

          if (user.role === 'localAdmin') {
            return 'Local Admin';
          }

          if (user.role === 'admin') {
            return 'Admin';
          }

          return user.role;
        },
      },
      {
        title: 'Permission',
        dataIndex: 'permission',
      },
      {
        title: 'Exchange',
        dataIndex: 'exchangeName',
      },
      {
        title: 'Action',
        render: (user) => {
          const props = {
            title: 'Are you sure delete this user?',
            okText: 'Yes',
            cancelText: 'No',
            onConfirm: () => deleteUser(user),
          };

          const editUser = () => {
            setDrawerVisible(true);
            setEditableUser(user);
          };

          return (
            <>
              <Button type="link" onClick={editUser}>
                Edit
              </Button>
              <Popconfirm {...props}>
                <Button danger type="text">
                  Delete
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];

    const tableProps = {
      dataSource: tableData,
      columns,
      pagination: false,
      loading: !tableData || !tableData.length,
      size: 'middle',
      scroll: { y: 600 },
    };

    return <Table {...tableProps} />;
  };

  const useUserAccessDrawer = () => {
    const updateUserAccess = (type, values) => {
      if (type === 'dsp') {
        setUserAccesses({
          ...userAccesses,
          dsps: values,
        });
      }
      if (type === 'ssp') {
        setUserAccesses({
          ...userAccesses,
          ssps: values,
        });
      }
      if (type === 'company') {
        setUserAccesses({
          ...userAccesses,
          dsps: userAccesses.dsps.filter((dspId) => {
            const dsp = dsps.find((x) => x.id === dspId);
            return values.includes(dsp.partnerId);
          }),
          ssps: userAccesses.ssps.filter((sspId) => {
            const ssp = ssps.find((x) => x.id === sspId);
            return values.includes(ssp.partnerId);
          }),
          companies: values,
        });
      }
    };

    const closeAccessDrawer = () => {
      setUserAccessDrawerVisible(false);
      setUserAccesses(null);
      setUserAccessUser(null);
    };

    const submitAccessDrawer = async () => {
      setUserAccessSpinning(true);

      try {
        const toCreate = [
          ...getOr(userAccesses, 'dsps', []).map((dspId) => ({ dspId })),
          ...getOr(userAccesses, 'ssps', []).map((sspId) => ({ sspId })),
          ...getOr(userAccesses, 'companies', []).map((companyId) => ({ companyId })),
        ].map((x) => ({ ...x, userId: userAccessUser.id, exchangeName: userAccessUser.exchangeName }));

        await apiGateway.users.createUserAccesses(toCreate);

        const toDelete = _([
          ...getOr(userAccesses, 'original.dsps', [])
            .map(({ id, dspId }) => {
              const del = !getOr(userAccesses, 'dsps', [dspId]).includes(dspId);
              return del ? id : null;
            })
            .filter(_.identity),
          ...getOr(userAccesses, 'original.ssps', [])
            .map(({ id, sspId }) => {
              const del = !getOr(userAccesses, 'ssps', [sspId]).includes(sspId);
              return del ? id : null;
            })
            .filter(_.identity),
          ...getOr(userAccesses, 'original.companies', [])
            .map(({ id, companyId }) => {
              const del = !getOr(userAccesses, 'companies', [companyId]).includes(companyId);
              return del ? id : null;
            })
            .filter(_.identity),
        ])
          .uniq()
          .value();

        await apiGateway.users.deleteUserAccesses(toDelete);

        notification.success({
          message: 'User access',
          description: 'User access was successfully updated',
        });

        closeAccessDrawer();
      } catch (e) {
        notification.error({
          message: 'Error when updating user access',
          description: e.message,
        });
      } finally {
        setUserAccessSpinning(false);
        await getData();
      }
    };

    const userAccessDrawerProps = {
      title: 'Edit user access',
      visible: userAccessDrawerVisible,
      width: 720,
      onClose: closeAccessDrawer,
      footer: (
        <Spin spinning={userAccessSpinning}>
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={closeAccessDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={submitAccessDrawer} type="primary">
              Submit
            </Button>
          </div>
        </Spin>
      ),
    };

    return (
      userAccessDrawerVisible && (
        <Drawer {...userAccessDrawerProps}>
          <Form.Item>
            <Typography.Title level={4}>DSPs Access</Typography.Title>
            <Select
              mode="multiple"
              onChange={(val) => updateUserAccess('dsp', val)}
              defaultValue={(userAccesses || []).dsps}
              value={(userAccesses || []).dsps}
              options={(dsps || [])
                .filter((x) => x && userAccessUser)
                .filter((x) => x.exchangeName === userAccessUser.exchangeName)
                .map((x) => ({ label: x.name, value: x.id }))}
            />
          </Form.Item>
          <Form.Item>
            <Typography.Title level={4}>SSPs Access</Typography.Title>
            <Select
              mode="multiple"
              onChange={(val) => updateUserAccess('ssp', val)}
              defaultValue={(userAccesses || []).ssps}
              value={(userAccesses || []).ssps}
              options={(ssps || [])
                .filter((x) => x && userAccessUser)
                .filter((x) => x.exchangeName === userAccessUser.exchangeName)
                .map((x) => ({ label: x.name, value: x.id }))}
            />
          </Form.Item>
          <Form.Item>
            <Typography.Title level={4}>Companies Access</Typography.Title>
            <Select
              mode="multiple"
              onChange={(val) => updateUserAccess('company', val)}
              value={(userAccesses || []).companies}
              defaultValue={(userAccesses || []).companies}
              options={(companies || [])
                .filter((x) => x && userAccessUser)
                .filter((x) => x.exchangeName === userAccessUser.exchangeName)
                .map((x) => ({ label: x.firstName, value: x.id }))}
            />
          </Form.Item>
        </Drawer>
      )
    );
  };

  const updateUser = async () => {
    try {
      await apiGateway.users.updateUser(editableUser);

      notification.success({
        message: 'User updated',
        description: `User with email ${editableUser.emailAddress} was successfully updated`,
      });

      closeDrawer();
    } catch (e) {
      notification.error({
        message: 'Error when updating user',
        description: e.message,
      });
    } finally {
      await getData();
    }
  };

  const createUser = async () => {
    try {
      await apiGateway.users.createUser(editableUser);

      notification.success({
        message: 'User created',
        description: 'User was successfully created',
      });

      closeDrawer();
    } catch (e) {
      notification.error({
        message: 'Error when creating user',
        description: e.message,
      });
    } finally {
      await getData();
    }
  };

  const useEditUserDrawer = () => {
    const upsertUser = () => {
      if (editableUser.id) {
        return updateUser();
      }
      return createUser();
    };

    const disable2fa = async () => {
      try {
        await apiGateway.users.disable2fa(editableUser.id);
      } catch (e) {
        notification.error({
          message: 'Error when disabling 2FA',
          description: e.message,
        });
      } finally {
        window.location.reload();
      }
    };

    const closeDrawer = () => {
      setDrawerVisible(false);
      setEditableUser(null);
    };

    const drawerProps = {
      title: 'Edit user',
      visible: drawerVisible,
      width: 720,
      onClose: closeDrawer,
      footer: (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={upsertUser} type="primary">
            Submit
          </Button>
        </div>
      ),
    };

    return (
      editableUser && (
        <Drawer {...drawerProps}>
          <Form layout="vertical">
            <Row>
              <Col md={12}>
                <Form.Item
                  label="Login"
                  name="login"
                  onChange={(e) => setEditableUser({ ...editableUser, login: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'Please input login!',
                    },
                  ]}
                >
                  <Input value={editableUser.login} defaultValue={editableUser.login} />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="emailAddress"
                  value={editableUser.emailAddress}
                  onChange={(e) => setEditableUser({ ...editableUser, emailAddress: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'Please input email!',
                    },
                  ]}
                >
                  <Input value={editableUser.emailAddress} defaultValue={editableUser.emailAddress} />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  onChange={(e) => setEditableUser({ ...editableUser, password: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'Please input password!',
                    },
                  ]}
                >
                  <Input value={editableUser.password} defaultValue={editableUser.password} />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  value={editableUser.firstName}
                  onChange={(e) => setEditableUser({ ...editableUser, firstName: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'Please input first name!',
                    },
                  ]}
                >
                  <Input value={editableUser.firstName} defaultValue={editableUser.firstName} />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  value={editableUser.lastName}
                  onChange={(e) => setEditableUser({ ...editableUser, lastName: e.target.value })}
                  rules={[
                    {
                      required: true,
                      message: 'Please input last name!',
                    },
                  ]}
                >
                  <Input value={editableUser.lastName} defaultValue={editableUser.lastName} />
                </Form.Item>
                <Form.Item>
                  <Typography.Title level={4}>Role</Typography.Title>
                  <Select
                    defaultValue="superUser"
                    onChange={(val) => setEditableUser({ ...editableUser, role: val })}
                    value={editableUser.role}
                  >
                    <Select.Option value="superUser">Manager</Select.Option>
                    <Select.Option value="superUserLimited">Limited Manager</Select.Option>
                    <Select.Option value="localAdmin">Local Admin</Select.Option>
                    {userInfo.role === 'admin' && <Select.Option value="admin">Global Admin</Select.Option>}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Typography.Title level={4}>Permission</Typography.Title>
                  <Select
                    defaultValue="create"
                    onChange={(val) => setEditableUser({ ...editableUser, permission: val })}
                    value={editableUser.permission}
                  >
                    <Select.Option value="create">Create</Select.Option>
                    <Select.Option value="view">View</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Typography.Title level={4}>Exchange</Typography.Title>
                  <Select
                    defaultValue="waardex"
                    onChange={(val) => setEditableUser({ ...editableUser, exchangeName: val })}
                    value={editableUser.exchangeName}
                  >
                    {userInfo.role === 'admin' && (
                      <>
                        <Select.Option value="waardex">Waardex</Select.Option>
                        <Select.Option value="mediahub">Mediahub</Select.Option>
                      </>
                    )}
                    {userInfo.role !== 'admin' && (
                      <Select.Option value={userInfo.exchangeName}>{userInfo.exchangeName}</Select.Option>
                    )}
                  </Select>
                </Form.Item>
                {(editableUser && editableUser.twoFaSecret && (
                  <Form.Item>
                    <Button type="primary" onClick={disable2fa}>
                      Disable 2FA
                    </Button>
                  </Form.Item>
                )) ||
                  ''}
              </Col>
            </Row>
          </Form>
        </Drawer>
      )
    );
  };

  if (userInfo && !['admin', 'localAdmin'].includes(userInfo.role)) {
    return <div>You do not have access to this page</div>;
  }

  return (
    <Container>
      <Row>
        <Col md={10}>
          {userInfo && userInfo.role === 'admin' && (
            <Form layout="inline" className="components-table-demo-control-bar" style={{ marginBottom: 16 }}>
              <Form.Item label="Exchange">
                <Radio.Group value={exchangeFilter} onChange={(e) => setExchangeFilter(e.target.value)}>
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value="waardex">waardex</Radio.Button>
                  <Radio.Button value="mediahub">mediahub</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Form>
          )}
        </Col>
        <Col md={2} style={{ textAlign: 'right' }}>
          <Button onClick={createNewUserDrawer}>New User</Button>
        </Col>
        <Col md={12}>{useTable()}</Col>

        {useUserAccessDrawer()}
        {useEditUserDrawer()}
      </Row>
    </Container>
  );
}
