import querystring from 'querystring';
import { type Endpoints } from '../constants/endpoints';
import { type Requester } from './index';

export enum WhiteAndBlackListName {
  blackListDspBundles = 'blackListDspBundles',
  blackListDspDomains = 'blackListDspDomains',
  blackListDspIPs = 'blackListDspIPs',
  blackListDspPublishers = 'blackListDspPublishers',
  whiteListDspPublishers = 'whiteListDspPublishers',
  blackListSspAdomains = 'blackListSspAdomains',
  blackListSspCrids = 'blackListSspCrids',
  whiteListDsp = 'whiteListDsp',
  whiteListDspBundles = 'whiteListDspBundles',
  whiteListDspCountries = 'whiteListDspCountries',
  whiteListDspDomains = 'whiteListDspDomains',
  whiteListDspOs = 'whiteListDspOs',
  whiteListDspSizes = 'whiteListDspSizes',
  whiteListSspCountries = 'whiteListSspCountries',
}

export enum WhiteAndBlackListType {
  White = 'W',
  Black = 'B',
}

export type ListWhiteAndBlackListQueryParams = {
  name: WhiteAndBlackListName;
  type: WhiteAndBlackListType;
  dsp_id?: number;
  ssd_id?: number;
};

export type ListWhiteAndBlackListResponse = {
  id: number;
  name: WhiteAndBlackListName;
  type: WhiteAndBlackListType;
  dsp_id: number | null;
  ssp_id: number | null;
  value: string;
  created_at: string;
}[];

export function createWhiteAndBlackLists({ authorizedGET }: Requester, endpoints: Endpoints) {
  const list = (queryParams: ListWhiteAndBlackListQueryParams) => {
    const url = `${endpoints.list.v2}?${querystring.stringify(queryParams)}`;

    return authorizedGET<ListWhiteAndBlackListResponse>(url);
  };

  return { list };
}
