import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { sortBy, uniqBy } from 'lodash';
import { useDsps } from '../../../hooks/useDsps';
import { PageLayout } from '../../../components/PageLayout';
import { Search } from '../ssps/Search';

export function DspsV2Page() {
  const dsps = useDsps();

  const [searchQuery, setSearchQuery] = useState();

  const getBidQps = (lastHourStatistics) =>
    lastHourStatistics ? Math.ceil(lastHourStatistics.dspBidResponseValid / 3600) : 0;

  const getReqQps = (lastHourStatistics) =>
    lastHourStatistics ? Math.ceil(lastHourStatistics.dspBidRequest / 3600) : 0;

  const columns = [
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (isActive ? 'Active' : 'Inactive'),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, { isActive }) => isActive === value,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, { id }) => (
        <Link to={`/dsps/${id}`}>
          [{id}] {name}
        </Link>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company.firstName',
      key: 'company.firstName',
      render: (value, record) => record.company.firstName,
      filters: uniqBy(
        sortBy(
          (dsps || []).map((dsp) => dsp.company),
          'firstName'
        ),
        'id'
      ).map((company) => ({
        text: company.firstName,
        value: company.id,
      })),
      onFilter: (value, { company }) => company.id === value,
    },
    {
      title: 'Traffic Type',
      key: 'trafficType',
      dataIndex: 'trafficType',
      render: (value, { useBanner, useVideo, useNative }) =>
        [useBanner && 'Banner', useVideo && 'Video', useNative && 'Native'].filter(Boolean).join(', ') || 'None',
      filters: [
        {
          text: 'Banner',
          value: 'banner',
        },
        {
          text: 'Video',
          value: 'video',
        },
        {
          text: 'Native',
          value: 'native',
        },
      ],
      onFilter: (value, { useBanner, useVideo, useNative }) => {
        if (value === 'banner') {
          return useBanner;
        }

        if (value === 'video') {
          return useVideo;
        }

        if (value === 'native') {
          return useNative;
        }

        return false;
      },
    },
    {
      title: 'Req',
      key: 'req',
      dataIndex: 'req',
      render: (value, { lastHourStatistics }) => (lastHourStatistics?.dspBidRequest || 0).toLocaleString(),
      sorter: (a, b) => (a.lastHourStatistics?.dspBidRequest || 0) - (b.lastHourStatistics?.dspBidRequest || 0),
    },
    {
      title: 'Res',
      key: 'res',
      dataIndex: 'res',
      render: (value, { lastHourStatistics }) => (lastHourStatistics?.dspBidResponseValid || 0).toLocaleString(),
      sorter: (a, b) =>
        (a.lastHourStatistics?.dspBidResponseValid || 0) - (b.lastHourStatistics?.dspBidResponseValid || 0),
    },
    {
      title: 'Real QPS',
      key: 'realQps',
      dataIndex: 'realQps',
      render: (value, { lastHourStatistics, limitQps }) =>
        `${lastHourStatistics?.dspBidRequest ? Math.round(lastHourStatistics.dspBidRequest / 3600) : 0} / ${limitQps}`,
      sorter: (a, b) => getReqQps(a.lastHourStatistics) - getReqQps(b.lastHourStatistics),
    },
    {
      title: 'Bid QPS',
      key: 'bidQps',
      dataIndex: 'bidQps',
      render: (value, { lastHourStatistics }) =>
        lastHourStatistics?.dspBidResponseValid ? Math.round(lastHourStatistics.dspBidResponseValid / 3600) : 0,
      defaultSortOrder: 'descend',
      sorter: (a, b) => getBidQps(a.lastHourStatistics) - getBidQps(b.lastHourStatistics),
    },
    {
      title: 'Margin %',
      dataIndex: 'margin',
      key: 'margin',
      render: (value) => value || 0,
      sorter: (a, b) => a.margin - b.margin,
    },
    {
      title: 'Rev. Share %',
      dataIndex: 'extraMargin',
      key: 'extraMargin',
      render: (value) => value || 0,
      sorter: (a, b) => a.extraMargin - b.extraMargin,
    },
    {
      title: 'Optimizer',
      dataIndex: 'optimizer',
      key: 'optimizer',
      render: (isActive) => (isActive ? 'Active' : 'Inactive'),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, { optimizer }) => optimizer === value,
    },
    {
      title: 'Resolve DNS',
      dataIndex: 'dnsResolve',
      key: 'dnsResolve',
      render: (isActive) => (isActive ? 'Active' : 'Inactive'),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, { dnsResolve }) => dnsResolve === value,
    },
    {
      title: 'Gzip',
      dataIndex: 'useGzip',
      key: 'useGzip',
      render: (isActive) => (isActive ? 'Active' : 'Inactive'),
      filters: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
      ],
      onFilter: (value, { useGzip }) => useGzip === value,
    },
  ];

  const sspsToDisplay = searchQuery
    ? dsps.filter(({ id, name }) => `[${id}] ${name}`.toLowerCase().includes(searchQuery.toLowerCase()))
    : dsps;

  return (
    <PageLayout>
      <Link to="/dsps/new">
        <Button>Create new DSP</Button>
      </Link>

      <Search onSearch={setSearchQuery} />

      <Table columns={columns} dataSource={sspsToDisplay} pagination={false} size="small" loading={dsps.length === 0} />
    </PageLayout>
  );
}
