import axios from 'axios';
import { user as userEndpoints } from '../../constants/endpoints';

export default class Login {
  static async call({ login, password, otp }) {
    const { data } = await axios.post(userEndpoints.login, { login, password, otp });
    const { token } = data;

    window.localStorage.setItem('accessToken', token);
    window.localStorage.setItem('login', login);
  }
}
