import React, { type ReactElement, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './header';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../pages/unauthorized/login/loginSlice';

export function AuthLayout(): ReactElement {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(getUserInfo());
    })();
  }, []);

  return (
    <div>
      <Header />

      <Outlet />
    </div>
  );
}
