import React, { type ReactElement } from 'react';
import { Tag } from 'antd';

export enum StatusEnum {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

type Props = {
  status: StatusEnum;
  label: string;
};

export function Status({ status, label }: Props): ReactElement {
  const statusMap = {
    [StatusEnum.success]: 'green',
    [StatusEnum.warning]: 'orange',
    [StatusEnum.error]: 'red',
  };

  const color = statusMap[status] ?? 'gray';

  return <Tag color={color}>{label}</Tag>;
}
