export default (obj) => {
  const result = { ...obj };

  for (const key in result) {
    if (result.hasOwnProperty(key)) {
      if (result[key] === false) {
        result[key] = 0;
      }

      if (result[key] === true) {
        result[key] = 1;
      }
    }
  }

  return result;
};
