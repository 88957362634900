import React, { type ReactElement, useState } from 'react';
import { Button, Flex, Table, Typography, notification } from 'antd';
import Bluebird from 'bluebird';
import { type Ssp, useSsps } from '../../../../../hooks/useSsps';
import createApiGateway from '../../../../../apiGateway';
import { UpsertSspFormBidRequestSwapper } from '../../../../../sections/ssps/UpsertSspFormBidRequestSwapper';

const DEFAULT = {
  BID_FLOOR_STEP: 0.05,
  MAX_ATTEMPTS: 10,
  RETRY_DELAY: 1000,
  START_DELAY: 30000,
};

type SwapperSettings = Pick<
  Ssp,
  'swapperEnableSwapper' | 'swapperBidFloorStep' | 'swapperMaxAttempts' | 'swapperRetryDelay' | 'swapperStartDelay'
>;

export function SwapperBulkManager(): ReactElement {
  const apiGateway = createApiGateway();
  const ssps = useSsps();

  const [newSwapperSettings, setNewSwapperSettings] = useState<SwapperSettings>();
  const [selectedSsps, setSelectedSsps] = useState<Ssp[]>([]);

  const onSave = () => {
    return Bluebird.map(
      selectedSsps,
      async (ssp) => {
        try {
          await apiGateway.ssps.updateSsp(ssp.id, newSwapperSettings);

          notification.success({
            message: `Swapper settings applied to SSP ${ssp.id}`,
          });
        } catch (error) {
          if (error instanceof Error) {
            notification.error({
              message: `Failed to apply swapper settings to SSP ${ssp.id}`,
              description: error.message,
            });
          }

          throw error;
        }
      },
      { concurrency: 4 }
    );
  };

  const onPreview = (values: unknown) => {
    setNewSwapperSettings(values as SwapperSettings);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Ssp[]) => {
      setSelectedSsps(selectedRows);
    },
  };

  const getNewSwapperSetting = (field: string, sspId: number): unknown | null => {
    if (!newSwapperSettings) {
      return null;
    }

    // @ts-expect-error fix this
    const newValue = newSwapperSettings[field];

    if (!newValue) {
      return null;
    }

    if (!selectedSsps.some((ssp) => ssp.id === sspId)) {
      return null;
    }

    return (
      <Typography.Text mark strong>
        {newValue}
      </Typography.Text>
    );
  };

  return (
    <div>
      <UpsertSspFormBidRequestSwapper
        ssps={ssps}
        onFinish={onPreview}
        actionButtonLabel={'Preview'}
        hide={{
          swapperEnableSwapper: true,
          selectSsp: true,
        }}
      />

      <Flex vertical gap={'20px'}>
        <Table
          loading={ssps.length === 0}
          dataSource={ssps
            .filter((ssp) => ssp.isActive)
            .filter((ssp) => ssp.swapperEnableSwapper)
            .map((ssp) => ({ ...ssp, key: ssp.id }))}
          columns={[
            {
              title: 'Active?',
              dataIndex: 'isActive',
              render: (value) => (value ? 'Yes' : 'No'),
            },
            {
              title: 'SSP',
              dataIndex: 'id',
              render: (value) => {
                const ssp = ssps.find((x) => x.id === value);

                return ssp ? `[${ssp.id}] ${ssp.name}` : value;
              },
            },
            {
              title: 'Swapper SSP',
              dataIndex: 'swapperSspId',
              render: (value) => {
                const ssp = ssps.find((x) => x.id === value);

                return ssp ? `[${ssp.id}] ${ssp.name}` : value;
              },
            },
            {
              title: 'Bid Floor Step',
              dataIndex: 'swapperBidFloorStep',
              render: (value, record) =>
                getNewSwapperSetting('swapperBidFloorStep', record.id) || value || DEFAULT.BID_FLOOR_STEP,
            },
            {
              title: 'Max Attempts',
              dataIndex: 'swapperMaxAttempts',
              render: (value, record) =>
                getNewSwapperSetting('swapperMaxAttempts', record.id) || value || DEFAULT.MAX_ATTEMPTS,
            },
            {
              title: 'Retry Delay',
              dataIndex: 'swapperRetryDelay',
              render: (value, record) =>
                getNewSwapperSetting('swapperRetryDelay', record.id) || value || DEFAULT.RETRY_DELAY,
            },
            {
              title: 'Start Delay',
              dataIndex: 'swapperStartDelay',
              render: (value, record) =>
                getNewSwapperSetting('swapperStartDelay', record.id) || value || DEFAULT.START_DELAY,
            },
          ]}
          size={'small'}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
        />

        <Button
          disabled={!Boolean(newSwapperSettings) || !selectedSsps.length}
          style={{ width: '100%' }}
          onClick={onSave}
          type={'primary'}
        >
          Apply New Swapper Settings
        </Button>
      </Flex>
    </div>
  );
}
