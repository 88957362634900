import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';

function FormInput({ name, type, className, value, onChange, label, hint, error, placeholder, disabled }) {
  return (
    <Form.Group className={classNames('text-left mb-0', className)}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {hint && <Form.Text>{hint}</Form.Text>}
      {error && <Form.Text className="text-danger">{hint}</Form.Text>}
    </Form.Group>
  );
}

FormInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

FormInput.defaultProps = {
  error: '',
  hint: '',
  label: null,
  type: 'text',
  placeholder: '',
};

export default FormInput;
