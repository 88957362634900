import { API_URL, EXCHANGE_NODE_BASE_URL } from './application';

const wrapWithAPIUrl = <T>(object: T): T => {
  const temp = {};

  // @ts-expect-error ...
  Object.entries(object).forEach(([key, value]) => {
    // @ts-expect-error ...
    temp[key] = `${API_URL}${value}`;
  });

  return temp as T;
};

export const user = wrapWithAPIUrl({
  login: '/login',
  root: '/user',
  me: '/user/me',
  access: '/users/access',
  users: '/users',
});

export const ssp = wrapWithAPIUrl({
  list: '/ssp',
});

export const dsp = wrapWithAPIUrl({
  list: '/dsp',
});

export const system = wrapWithAPIUrl({
  status: '/system/status',
  changeMode: '/system/change-mode',
});

export const samples = wrapWithAPIUrl({
  list: '/samples',
  samplesRequest: '/samples/request',
});

export const list = wrapWithAPIUrl({
  v2: '/white-and-black-lists',
  v2Delete: '/white-and-black-lists/:id',
});

export const statistics = {
  hourly: {
    dsp: (id = null) => `${API_URL}/statistics/hourly/dsp${id ? `/${id}` : ''}`,
    ssp: (id = null) => `${API_URL}/statistics/hourly/ssp${id ? `/${id}` : ''}`,
    common: {
      ssp: (id = null) => `${API_URL}/statistics/hourly/common/ssp${id ? `/${id}` : ''}`,
      dsp: (id = null) => `${API_URL}/statistics/hourly/common/dsp${id ? `/${id}` : ''}`,
      dspV1: (id = null) => `[v1]${API_URL}/statistics/hourly/common/dsp${id ? `/${id}` : ''}`,
      sspV1: (id = null) => `[v1]${API_URL}/statistics/hourly/common/ssp${id ? `/${id}` : ''}`,
      partner: (id: unknown) => `${API_URL}/statistics/hourly/common/partner/${id || ''}`,
    },
    total: `${API_URL}/statistics/hourly/common/total`,
    totalEndpoint: `${API_URL}/statistics/hourly/common/total/endpoint`,
  },
  eventLogs: () => `${API_URL}/eventLogs`,
  qps: `${API_URL}/statistics/qps`,
  tables: `${API_URL}/auction-statistics/tables`,
  auctionStatisticsByTable: (table: string) => `${API_URL}/auction-statistics/tables/${table}`,
};

const globalLists = {
  root: `${API_URL}/globalLists`,
  categories: `${API_URL}/globalLists/categories`,
};

const endpoints = {
  user,
  ssp,
  dsp,
  samples,
  system,
  statistics,
  list,
  sspIabCategories: `${API_URL}/ssps/iabCategories`,
  sspSellerInfo: `${API_URL}/ssp/:id/sellerInfo`,
  globalLists,
  lists: `${API_URL}/lists`,
  exchangeNodeBaseUrl: EXCHANGE_NODE_BASE_URL,
  asianExchangeBaseUrl: 'http://sg.justbidit.xyz:8800',
};

export type Endpoints = typeof endpoints;

export default endpoints;
