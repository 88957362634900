import { useEffect, useState } from 'react';
import { createApiGateway } from '../apiGateway';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { notification } from 'antd';

dayjs.extend(utc);

const apiGateway = createApiGateway();

export type Ssp = {
  id: number;
  name: string;
  isActive: boolean;
  swapperEnableSwapper: boolean | null;
  swapperSspId: number | null;
  swapperBidFloorStep: number | null;
  swapperMaxAttempts: number | null;
  swapperRetryDelay: number | null;
  swapperStartDelay: number | null;
};

export function useSsps() {
  const [ssps, setSsps] = useState<Ssp[]>([]);

  // @ts-expect-error TODO: fix this
  const getLastHourStatistics = async (hoursToSubtract = 1) => {
    const from = dayjs.utc().subtract(hoursToSubtract, 'hour').format('YYYY-MM-DD HH:00:00');
    const to = dayjs.utc().format('YYYY-MM-DD HH:00:00');

    const response = await apiGateway.auctionStatistics.listStatisticsByTable({
      table: 'hourlyStatistics.auctionByHour',
      from,
      to,
      dimensions: ['sspId', 'dateYMDH'],
    });

    if (response && response.rows && response.rows.length) {
      notification.info({
        message: `Statistics loaded for ${from} UTC`,
        duration: 1,
      });

      return response;
    }

    notification.info({
      message: `No data for ${from} UTC, subtracting one hour... Refresh the page to try this time again`,
      duration: 1,
    });

    return getLastHourStatistics(hoursToSubtract + 1);
  };

  useEffect(() => {
    (async () => {
      const [sspsResponse, usersResponse, lastHourStatisticsResponse] = await Promise.all([
        apiGateway.ssps.getAll(),
        apiGateway.users.getAll(),
        getLastHourStatistics(),
      ]);

      // @ts-expect-error TODO: fix this
      const sspsToSet = sspsResponse.map((ssp) => {
        // @ts-expect-error TODO: fix this
        const company = usersResponse.find((u: User) => u.id === ssp.partnerId) || null;

        return {
          ...ssp,
          company,
          // @ts-expect-error TODO: fix this
          lastHourStatistics: lastHourStatisticsResponse.rows.find((x) => String(x.sspId).includes(`[${ssp.id}]`)),
        };
      });

      setSsps(sspsToSet);
    })();
  }, []);

  return ssps;
}
