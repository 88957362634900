import React from 'react';
import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';

export function Search({ onSearch }) {
  const onFinish = (values) => onSearch(values.search);

  return (
    <Form onFinish={onFinish} style={{ padding: '10px 0' }}>
      <Form.Item name="search" style={{ width: '100%', marginBottom: '10px' }}>
        <Input placeholder="Input search query" />
      </Form.Item>

      <Form.Item style={{ margin: 0 }}>
        <Button type="primary" htmlType="submit">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
