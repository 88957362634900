import { type Table as ApiTable, type TableField } from '../../../../apiGateway/auctionStatistics';
import { type Column } from '../index';

function toColumn(field: TableField): Column {
  return {
    title: field.name,
    dataIndex: field.key,
    key: field.key,
    sorter: true,
  };
}

export function getColumns(table: ApiTable, dimensions: string[]) {
  const dimensionsColumns = table.fields
    .filter((field) => field.type === 'DIMENSION')
    .filter((column) => dimensions.includes(column.key))
    .map(toColumn);

  const regularColumns = table.fields.filter((field) => field.type === 'ATTRIBUTE').map(toColumn);

  return { regularColumns, dimensionsColumns };
}

export function getDefaultDimensions(table?: ApiTable): string[] {
  if (!table) {
    return ['dateYMDH'];
  }

  return (
    {
      ['hourlyStatistics.auctionByHour']: ['dateYMDH'],
      ['hourlyStatistics.auctionByOS']: ['os'],
      ['hourlyStatistics.auctionByCountry']: ['country'],
      ['hourlyStatistics.auctionByDeviceType']: ['devicetype'],
      ['hourlyStatistics.auctionBySize']: ['bannerSize'],
      ['hourlyStatistics.auctionBySite']: ['siteDomain', 'siteName'],
      ['hourlyStatistics.auctionByApp']: ['appBundle', 'appName'],
      ['hourlyStatistics.auctionByPublisher']: ['publisherId', 'publisherName'],
      ['hourlyStatistics.shortTermAuction']: ['dateYMDH'],
    }[table.key] || []
  );
}
