export default ({ authorizedGET, authorizedPOST }, endpoints) => {
  const getSystemStatus = async () => authorizedGET(endpoints.system.status);

  const changeMode = (ip, mode) => authorizedPOST(endpoints.system.changeMode, { nodeIp: ip, mode });

  return {
    getSystemStatus,
    changeMode,
  };
};
