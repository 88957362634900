/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { notification } from 'antd';

import { FormInput, Table } from './index';
import { authorizedGET } from '../helpers/requester';

/*
    dataFilterFunc - function which should filter data from endpoint based on input from user
        as first argument it will take input from search text input
        as second argument it will take an array of objects retrieved from API
        it should return an array of objects
        It wouldn't be called if no data retrieved from API
    filters - array of objects with functions which will be applied to data to filter data fetched from API
        each object should have following properties:
            * id - unique id
            * title - name which will be displayed in the UI
            * type - type of the field
                * allowed types:
                    * checkbox
            * activeFn - filter function which will fetch data as first parameter and should return filtered data
                it will be called when this filter is "active" (checkbox is active)
            * inactiveFn - filter function which will fetch data as first parameter and should return filtered data
                it will be called when this filter is not active (checkbox is not active)
        all properties above are required
 */
function Search(props) {
  const {
    tooltips,
    title,
    placeholder,
    baseUrl,
    onSelect,
    dataFilterFunc,
    dataMapper,
    filters,
    data = [],
    defaultSortBy,
    defaultSortByOrder,
  } = props;

  const initialFilters = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  filters &&
    filters.forEach((item) => {
      initialFilters[item.id] = item.inactiveFn;
    });

  const [state, setState] = useState({
    input: '',
    data: [],
    filters: initialFilters,
  });
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    setState({ ...state, data });
    setOriginalData(data || []);
  }, Object.values(props));

  const onInputChange = (e) => {
    setState({
      ...state,
      input: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let { data } = state;

      if (baseUrl) {
        // TODO: pagination should not be hardcoded
        const url = `${baseUrl}?offset=0&limit=10000`;
        const response = await authorizedGET(url);

        data = response || [];
      }

      if (data && dataFilterFunc) {
        data = dataFilterFunc(state.input, (originalData.length && originalData) || data);
      }

      Object.keys(state.filters).forEach((key) => {
        data = state.filters[key](originalData);
      });

      setState({ ...state, data });
    } catch (error) {
      notification.error({ message: `Oops, sth went wrong...\n${error}` });
    }
  };

  const onCheckboxFilterChange = (id, activeFn, inactiveFn) => {
    let isActive = false;

    setState((prevState) => {
      isActive = !prevState[id];

      return { ...prevState, [id]: !prevState[id] };
    });

    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [id]: isActive ? activeFn : inactiveFn,
      },
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getFilters = (filters) =>
    filters.map((item) => (
      <div key={item.id}>
        {item.type === 'checkbox' && (
          <Form.Check
            key={item.id}
            type="checkbox"
            label={item.title}
            onChange={() => onCheckboxFilterChange(item.id, item.activeFn, item.inactiveFn)}
            checked={state[item.id] || false}
          />
        )}
      </div>
    ));

  const beforeOnSelect = (selectedId) => {
    if (!onSelect) return;

    const item = state.data.find((o) => o.id === selectedId);

    console.log(item);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    item && onSelect(item);
  };

  return (
    <Row style={{ overflowX: 'scroll' }}>
      <Col md={12}>
        <Form onSubmit={onSubmit}>
          <FormInput
            type="text"
            placeholder={placeholder}
            label={title}
            name="name"
            className="m-2"
            onChange={onInputChange}
          />
          {filters && getFilters(filters)}
          <Button type="submit" className="m-2">
            Search
          </Button>
        </Form>
      </Col>
      <Col md={12}>
        <Table
          tooltips={tooltips}
          selectKey="id"
          data={(dataMapper && dataMapper(state.data)) || state.data}
          onSelect={beforeOnSelect}
          formatNumbers
          defaultSortBy={defaultSortBy}
          defaultSortByOrder={defaultSortByOrder}
        />
      </Col>
    </Row>
  );
}

Search.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  defaultSortBy: PropTypes.string,
  defaultSortByOrder: PropTypes.string,
  baseUrl: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  dataFilterFunc: PropTypes.func,
  data: PropTypes.array,
};

export default Search;
