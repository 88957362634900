import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const layoutStyle = {
  backgroundColor: 'transparent',
};

const contentStyle = {
  margin: '10px',
};

// eslint-disable-next-line react/prop-types
export function PageLayout({ children }) {
  return (
    <Layout style={layoutStyle}>
      <Content style={contentStyle}>{children}</Content>
    </Layout>
  );
}
