import React, { useEffect, useState } from 'react';

function Spinner(props) {
  // eslint-disable-next-line react/prop-types
  const [loading, setLoading] = useState(props.loading || false);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    setLoading(props.loading);
  }, [props]);

  if (!loading) return <div />;
  return <div className="sweet-loading">loading...</div>;
}

export default Spinner;
