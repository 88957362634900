import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { Select, Form, Button, Input, Checkbox, Popconfirm, notification } from 'antd';
import { createApiGateway } from '../../../../apiGateway';

const { Option } = Select;

function SellerInfo(props) {
  // eslint-disable-next-line react/prop-types
  const { sspId } = props;
  const apiGateway = createApiGateway();
  const [form] = Form.useForm();

  const [sellerInfo, setSellerInfo] = useState(null);

  const getSellerInfo = async () => {
    if (sspId) {
      const response = await apiGateway.ssps.getSellerInfo(sspId);
      setSellerInfo(_.get(response, 'data'));
    }
  };

  useEffect(() => {
    (async () => {
      await getSellerInfo();
    })();
  }, []);

  const onFinish = async (values) => {
    const sellerInfoDTO = _.pickBy(
      {
        ...values,
        passThrough: values.passThrough ? 1 : 0,
      },
      _.identity
    );

    try {
      if (sellerInfo && sellerInfo.id) {
        await apiGateway.ssps.updateSellerInfo(sspId, sellerInfoDTO);
      } else {
        await apiGateway.ssps.createSellerInfo(sspId, sellerInfoDTO);
      }

      await getSellerInfo();

      notification.success({ message: 'OK' });
    } catch (e) {
      notification.error({ message: e.toString() });
    }
  };

  const deleteSellerInfo = async () => {
    try {
      await apiGateway.ssps.deleteSellerInfo(sspId);

      form.resetFields();

      notification.success({ message: 'OK' });
    } catch (e) {
      notification.error({ message: e.toString() });
    }
  };

  if (sellerInfo) {
    console.log('SellerInfo.sellerName', sellerInfo);

    form.setFields([
      {
        name: 'sellerName',
        value: sellerInfo && sellerInfo.sellerName,
      },
      {
        name: 'sellerType',
        value: sellerInfo && sellerInfo.sellerType,
      },
      {
        name: 'sellerDomain',
        value: sellerInfo && sellerInfo.sellerDomain,
      },
      {
        name: 'passThrough',
        value: sellerInfo && sellerInfo.passThrough,
      },
      {
        name: 'comment',
        value: sellerInfo && sellerInfo.comment,
      },
    ]);
  }

  return (
    <div>
      <h3>RTB Seller Info</h3>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          name="sellerType"
          label="Seller Type"
          rules={[{ required: true, message: 'Please select Seller Type' }]}
        >
          <Select style={{ width: 300 }}>
            <Option value="publisher">Publisher</Option>
            <Option value="intermediary">Intermediary</Option>
            <Option value="both">Both</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="sellerName"
          label="Seller Name"
          rules={[{ required: true, message: 'Please select Seller Name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="sellerDomain"
          label="Seller Domain"
          rules={[{ required: true, message: 'Please select Seller Domain' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="passThrough" valuePropName="checked">
          <Checkbox>Seller Passthrough</Checkbox>
        </Form.Item>

        <Form.Item name="comment" label="Comment">
          <Input.TextArea />
        </Form.Item>

        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              {sellerInfo && sellerInfo.id && (
                <Popconfirm
                  title="Are you sure delete this RTB Seller Info?"
                  onConfirm={deleteSellerInfo}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" style={{ marginLeft: 10 }}>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>
            <div>
              <Button type="link">
                <a href={process.env.REACT_APP_API_URL + '/sellers.json'} target="_blank" rel="noreferrer">
                  Open sellers.json
                </a>
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SellerInfo;
