import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, InputNumber, Checkbox, Select } from 'antd';
import { type Ssp } from '../../hooks/useSsps';

type UpsertSspFormBidRequestSwapperProps = {
  ssp?: Ssp;
  ssps: Ssp[];
  onFinish: (values: unknown) => void;
  actionButtonLabel?: string;
  hide?: {
    swapperEnableSwapper?: boolean;
    selectSsp?: boolean;
  };
};

export function UpsertSspFormBidRequestSwapper(props: UpsertSspFormBidRequestSwapperProps) {
  const { ssp, ssps, onFinish, actionButtonLabel, hide } = props;

  // @ts-expect-error fix this
  const userInfo = useSelector((state) => state.login);

  return (
    <Form layout="vertical" onFinish={onFinish}>
      {hide?.swapperEnableSwapper !== true && (
        <Form.Item
          name="swapperEnableSwapper"
          valuePropName="checked"
          initialValue={ssp?.swapperEnableSwapper || false}
        >
          <Checkbox>Enable Bid Request Swapper</Checkbox>
        </Form.Item>
      )}

      <Form.Item name="swapperEnableIframe" valuePropName="checked" initialValue={true} hidden>
        <Checkbox>Encapsulate the AD within an iframe HTML Element</Checkbox>
      </Form.Item>

      {hide?.selectSsp !== true && (
        <Form.Item
          label="Select SSP"
          name="swapperSspId"
          initialValue={ssp?.swapperSspId}
          extra="The SSP that will be used to make the bid requests. We will immitate the original bid request and send it from the selected SSP."
        >
          <Select
            placeholder="Select an SSP"
            style={{ width: '100%' }}
            options={ssps.map((x) => ({ label: `[${x.id}] ${x.name}`, value: x.id }))}
            showSearch
            filterOption={(input, option) =>
              ((option && option.label) || '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      )}

      {userInfo && ['admin', 'localAdmin'].includes(userInfo.role) && (
        <>
          <Form.Item
            label="Bid Floor Step"
            name="swapperBidFloorStep"
            initialValue={ssp?.swapperBidFloorStep}
            extra="The amount deducted from the original bid request's bid floor in each request. The process continues until a bid response is received or the maximum number of attempts is reached."
          >
            <InputNumber step={0.01} min={0} style={{ width: '100%' }} placeholder="0.05" />
          </Form.Item>

          <Form.Item
            label="Max Attempts"
            name="swapperMaxAttempts"
            initialValue={ssp?.swapperMaxAttempts}
            extra="The number of times a bid request is made when no bid is received."
          >
            <InputNumber min={1} style={{ width: '100%' }} placeholder="10" />
          </Form.Item>

          <Form.Item
            label="Retry Delay (ms)"
            name="swapperRetryDelay"
            initialValue={ssp?.swapperRetryDelay}
            extra="The duration (in milliseconds) to wait before making a retry request when no bid is received."
          >
            <InputNumber min={0} style={{ width: '100%' }} placeholder="1000" />
          </Form.Item>

          <Form.Item
            label="Start Delay (ms)"
            name="swapperStartDelay"
            initialValue={ssp?.swapperStartDelay}
            extra="The time (in milliseconds) to wait before starting the bid requests and swapping ads."
          >
            <InputNumber min={0} style={{ width: '100%' }} placeholder="30000" />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {actionButtonLabel ?? 'Save'}
        </Button>
      </Form.Item>
    </Form>
  );
}
