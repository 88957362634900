import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import useApiGateway from '../../../apiGateway';

const apiGateway = useApiGateway();

export const getUserInfo = createAsyncThunk('login/getUserInfo', async () => {
  const userInfo = await apiGateway.users.getUserInfo();

  return userInfo.data;
});

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    login: null,
    role: null,
    permission: null,
    scope: null,
  },
  reducers: {},
  extraReducers: {
    [getUserInfo.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export default loginSlice.reducer;
