import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import _ from 'lodash';
import { Table, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { default as createApiGateway } from '../../../apiGateway';
import { useSsps } from '../../../hooks/useSsps';
import { useDsps } from '../../../hooks/useDsps';
import { TableSummary } from './TableSummary';

export default function FinancesPage() {
  const apiGateway = createApiGateway();

  const ssps = useSsps();
  const dsps = useDsps();

  const [state, setState] = useState({
    period: null,
    group_by: null,
    ssp: null,
    dsp: null,
    company: null,
    dateTimeUnit: 'whole',
  });

  const [dates, setDates] = useState({
    start: dayjs(),
    end: dayjs().add(1, 'day'),
  });

  const [companies, setCompanies] = useState([]);

  const [loading, setLoading] = useState(true);

  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    (async () => {
      const allUsers = await apiGateway.users.getAll({ allowedRoles: ['user'] });
      setCompanies(allUsers);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const response = await apiGateway.auctionStatistics.listStatisticsByTable({
        table: 'hourlyStatistics.auctionByHour',
        from: dates.start.format('YYYY-MM-DD'),
        to: dates.end.format('YYYY-MM-DD'),
        sspId: state.ssp,
        dspId: state.dsp,
        companyId: state.company,
        dimensions: [
          ...(state.dateTimeUnit === 'whole' ? [] : [state.dateTimeUnit]),
          ...(Boolean(state.group_by) ? [state.group_by] : []),
        ],
        orderDirection: 'DESC',
        orderBy: 'profit',
        limit: 1000,
      });

      setLoading(false);
      setStatistics(response);
    })();
  }, [...Object.values(state), ...Object.values(dates)]);

  const groupBy = [
    { label: 'DSP', value: 'dspId' },
    { label: 'SSP', value: 'sspId' },
  ];

  const dateTimeUnits = [
    { label: 'Hour', value: 'dateYMDH' },
    { label: 'Day', value: 'dateYMD' },
    { label: 'Whole Period', value: 'whole' },
  ];

  const onRangeChange = ([from, to]) => {
    setDates({ start: from, end: to.add(1, 'day') });
  };

  const onSelectChange = (name) => (value) => {
    let newState = {};

    if (!value) {
      newState = {
        ...state,
        [name]: null,
      };
    } else {
      newState = {
        ...state,
        [name]: value,
      };
    }

    if (name === 'ssp') newState.dsp = null;
    if (name === 'dsp') newState.ssp = null;

    setState(newState);
  };

  const columns = [
    state.dateTimeUnit === 'whole'
      ? null
      : {
          title: 'Date',
          dataIndex: 'dateYMD',
          key: 'dateYMD',
          // eslint-disable-next-line @typescript-eslint/no-shadow
          render: (_, record) => record.dateYMDH || record.dateYMD,
          sorter: (a, b) => dayjs(a.dateYMDH || a.dateYMD).format('x') - dayjs(b.dateYMDH || b.dateYMD).format('x'),
        },
    {
      title: 'SSP',
      dataIndex: 'ssp',
      key: 'ssp',
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render: (_, record) => (record.sspId ? record.sspId : 'Any'),
      sorter: (a, b) =>
        _.get(
          ssps.find((ssp) => ssp.id === a.sspId),
          'label[0]',
          ''
        ).toLowerCase() >
        _.get(
          ssps.find((ssp) => ssp.id === b.sspId),
          'label[0]',
          ''
        ).toLowerCase()
          ? 1
          : -1,
    },
    {
      title: 'DSP',
      dataIndex: 'dsp',
      key: 'dsp',
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render: (_, record) => (record.dspId ? record.dspId : 'Any'),
      sorter: (a, b) =>
        _.get(
          dsps.find((dsp) => dsp.value === a.dspId),
          'label[0]',
          ''
        ).toLowerCase() >
        _.get(
          dsps.find((dsp) => dsp.value === b.dspId),
          'label[0]',
          ''
        ).toLowerCase()
          ? 1
          : -1,
    },
    {
      title: 'Bids',
      dataIndex: 'sspBidResponseBid',
      key: 'sspBidResponseBid',
      render: (value) => value,
      sorter: (a, b) => numeral(a.sspBidResponseBid).value() - numeral(b.sspBidResponseBid).value(),
    },
    {
      title: 'Wins',
      dataIndex: 'wins',
      key: 'wins',
      render: (value) => value,
      sorter: (a, b) => numeral(a.wins).value() - numeral(b.wins).value(),
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      render: (value) => value,
      sorter: (a, b) => numeral(a.impressions).value() - numeral(b.impressions).value(),
    },
    {
      title: 'SSP Spend',
      dataIndex: 'price',
      key: 'price',
      render: (value) => value,
      sorter: (a, b) => numeral(a.price).value() - numeral(b.price).value(),
    },
    {
      title: 'DSP Spend',
      dataIndex: 'dspBidResPrice',
      key: 'dspBidResPrice',
      render: (value) => value,
      sorter: (a, b) => numeral(a.dspBidResPrice).value() - numeral(b.dspBidResPrice).value(),
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render: (_, record) => Number(record.profit),
      sorter: (a, b) => numeral(a.profit).value() - numeral(b.profit).value(),
    },
  ].filter(_.identity);

  const tableData = _.sortBy(statistics?.rows || [], (x) => x.dateYMDH || x.dateYMD).map((row) =>
    _.fromPairs(
      columns.reduce(
        (acc, column) => [
          ...acc,
          [column.dataIndex, column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]],
        ],
        []
      )
    )
  );

  return (
    <>
      <Container>
        <Card className="mb-4">
          <Card.Body style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
            <div style={{ width: '100%', display: 'flex', columnGap: '8px', justifyContent: 'center' }}>
              <DatePicker.RangePicker
                onChange={onRangeChange}
                defaultValue={[dates.start, dates.end]}
                style={{ width: '100%', maxWidth: '290px' }}
              />

              <Select
                options={groupBy}
                onChange={onSelectChange('group_by')}
                placeholder="Select Group By"
                value={state.group_by}
                style={{ width: '100%', maxWidth: '290px' }}
                allowClear
              />

              <Select
                options={dateTimeUnits}
                onChange={onSelectChange('dateTimeUnit')}
                placeholder="Select DateTime Unit"
                value={state.dateTimeUnit}
                style={{ width: '100%', maxWidth: '290px' }}
                allowClear
              />
            </div>

            <div style={{ width: '100%', display: 'flex', columnGap: '8px', justifyContent: 'center' }}>
              <Select
                options={[
                  ..._.sortBy(
                    (ssps || []).map((x) => ({ ...x, label: `[${x.id}] ${x.name}`, value: x.id })),
                    'id'
                  ),
                ]}
                onChange={onSelectChange('ssp')}
                placeholder="Select SSP"
                value={state.ssp}
                style={{ width: '100%', maxWidth: '290px' }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  ((option && option.label) || '').toLowerCase().includes(input.toLowerCase())
                }
              />

              <Select
                options={[
                  ..._.sortBy(
                    (dsps || []).map((x) => ({ ...x, label: `[${x.id}] ${x.name}`, value: x.id })),
                    'id'
                  ),
                ]}
                onChange={onSelectChange('dsp')}
                placeholder="Select DSP"
                value={state.dsp}
                style={{ width: '100%', maxWidth: '290px' }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  ((option && option.label) || '').toLowerCase().includes(input.toLowerCase())
                }
              />

              <Select
                options={[
                  { value: 'all', label: 'All Companies' },
                  ..._.sortBy(
                    companies.map((x) => ({
                      ...x,
                      value: x.id,
                      label: `[${x.id}] ${x.firstName}`,
                    })),
                    'id'
                  ),
                ]}
                onChange={onSelectChange('company')}
                placeholder="Select Company"
                value={state.company}
                style={{ width: '100%', maxWidth: '290px' }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  ((option && option.label) || '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </div>
          </Card.Body>
        </Card>
      </Container>

      <div style={{ overflowX: 'auto' }}>
        <Table
          size="small"
          pagination={false}
          dataSource={tableData}
          loading={loading}
          columns={columns.map((x) => _.omit(x, 'render'))}
          summary={(pageData) => <TableSummary data={pageData} dateTimeUnit={state.dateTimeUnit} />}
        />
      </div>
    </>
  );
}
