/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Popconfirm, Tooltip } from 'antd';

const getTableHeaders = ({ data, onDelete, tableHeadersPriorities }) => {
  let result = [];
  if (onDelete) result.push('DELETE');
  result = [...result, ...Object.keys(data[0])];

  if (tableHeadersPriorities) {
    result = _.sortBy(result, (x) => tableHeadersPriorities[x] || 0);
  }

  return result;
};

/*
    data - data which will be displayed
    selectKey - primary key which will be provided to "onSelect" function
    onSelect - function which will be called when anything will be selected by user
        as argument it will fetch "selectKey" value
    onDelete - function which will be called when user want to delete anything
        as first argument will be event
        as second argument will be item which should be deleted
 */
function CustomTable(props) {
  const {
    data: originalData,
    selectKey,
    onSelect,
    onSelectFull,
    formatNumbers = false,
    defaultSortBy,
    defaultSortByOrder,
    hiddenColumns = [],
    tableHeadersPriorities,
    tooltips,

    onDelete,
    onToggle,
  } = props;

  const [data, setData] = useState([]);
  const [sortOptions] = useState([]);

  useEffect(() => {
    if (defaultSortBy && defaultSortByOrder && originalData.length) {
      setData(originalData);
    } else if (sortOptions) {
      setData(originalData);
    } else {
      setData(originalData || []);
    }
  }, Object.values(props));

  if (!data || !data.length) return <div>No data</div>;

  const keys = getTableHeaders({
    data,
    onDelete,
    tableHeadersPriorities,
  });

  const displayRow = (value) => value;

  const onThClick = () => {
    setData(data);
  };

  return (
    <div style={{ overflowX: 'auto', width: '100%', maxHeight: 600 }}>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {keys
              .filter((k) => !hiddenColumns.includes(k))
              .map((k, index) => (
                <th key={index} onClick={(e) => onThClick(e, k)}>
                  <Tooltip title={_.get(tooltips, k)}>
                    <div className="d-flex justify-content-center align-items-center">
                      {k === '__toggle' ? '' : k}
                      {(sortOptions.find((x) => x.key === k) || {}).direction === 'asc' && (
                        <FontAwesomeIcon icon={faArrowUp} />
                      )}
                      {(sortOptions.find((x) => x.key === k) || {}).direction === 'desc' && (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )}
                    </div>
                  </Tooltip>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              {keys.map((k, index) => {
                if (hiddenColumns.includes(k)) {
                  return;
                }

                if (selectKey && onSelect && selectKey === k) {
                  return (
                    <td key={index}>
                      <Button
                        variant="link"
                        onClick={() => {
                          if (onSelectFull) onSelect(d);
                          else onSelect(d[selectKey]);
                        }}
                      >
                        {d[k]}
                      </Button>
                    </td>
                  );
                }
                if (k === 'DELETE') {
                  return (
                    <td key={index}>
                      <Popconfirm
                        title="Are you sure you want to delete it?"
                        onConfirm={(e) => onDelete(e, d)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button variant="outline-danger" size="sm">
                          <FontAwesomeIcon icon={faTrash} size="sm" />
                        </Button>
                      </Popconfirm>
                    </td>
                  );
                }
                if (k === '__toggle') {
                  return (
                    <td key={d.key}>
                      <input type="checkbox" checked={d.__toggle} onChange={() => onToggle(d)} />
                    </td>
                  );
                }
                let value = (formatNumbers && displayRow(d[k])) || d[k];

                if (value === '+') {
                  value = <FontAwesomeIcon icon={faCircle} size="xs" color="lightgreen" />;
                }

                if (value === '-') {
                  value = <FontAwesomeIcon icon={faCircle} size="xs" color="red" />;
                }

                return <td key={index}>{value}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

CustomTable.propTypes = {
  data: PropTypes.array,
};

export default CustomTable;
