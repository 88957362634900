import React, { useState } from 'react';

import { notification, Table, Modal, Typography } from 'antd';
import _ from 'lodash';

import AntDragAndDrop from './AntDragAndDrop';

// eslint-disable-next-line react/prop-types
export default function ListDragAndDrop({ onOk }) {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    onOk(data);
    setIsModalOpen(false);
    setData([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData([]);
  };

  const modalDragAndDropProps = {
    name: 'file',
    accept: '.txt',
    multiple: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = function () {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const data = _.uniq(
          reader.result
            .split('\n')
            .map((x) => x.split(','))
            .flat()
            .map(_.trim)
            .filter(_.identity)
        );

        setData(data);
        setIsModalOpen(true);
      };

      reader.onerror = function () {
        notification.error({
          message: 'File reading error',
          description: reader.error,
        });
      };

      return false;
    },
    supportText: 'Support only .txt files. Data in the file should be separated by enter or comma',
  };

  return (
    <>
      <Modal title="Preview" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Table
          size="small"
          dataSource={data.map((x, index) => ({ title: x, index }))}
          pagination={false}
          columns={[
            {
              title: '#',
              dataIndex: 'index',
            },
            {
              title: 'Title',
              dataIndex: 'title',
            },
          ]}
          scroll={{ y: 300 }}
        />

        <Typography.Text type="secondary">
          Please note that clicking the Ok button will not save your data. Kindly click on the Save button to ensure
          that your data is saved properly.
        </Typography.Text>
      </Modal>

      <AntDragAndDrop {...modalDragAndDropProps} />
    </>
  );
}
