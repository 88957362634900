import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { Pagination, Input, Select, Form, Button } from 'antd';
import { Spinner, Table } from '../../../components';
import { default as createApiGateway } from '../../../apiGateway';

const { Option } = Select;

function EventLogsPage() {
  const apiGateway = createApiGateway();

  const [eventLogs, setEventLogs] = useState([]);
  const [totalEventLogs, setTotalEventLogs] = useState(0);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [dsps, setDsps] = useState([]);
  const [ssps, setSsps] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [eventTypeValue, setEventTypeValue] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const refreshData = async (options = {}) => {
    try {
      setLoading(true);

      const offset = (options.pageNumber - 1) * options.pageSize;
      const limit = options.pageSize;

      let eventLogsResponse = await apiGateway.statistics.getEventLogs({
        offset,
        limit,
        search: options.search || '',
      });

      setTotalEventLogs(eventLogsResponse.meta.totalLogs);

      eventLogsResponse = eventLogsResponse.data
        .map((x) => {
          const user = options.users.find((u) => u.id === x.userId);

          if (user && user.role === 'admin') {
            return false;
          }

          if (x.data) {
            x.data = JSON.parse(x.data);
          }

          if (x.event.toLowerCase() === 'update ssp' && x.data.id) {
            const ssp = options.ssps.find((s) => s.id === x.data.id);
            x.data.name = (ssp && ssp.name) || 'unknown ssp';
          }

          if (x.event.toLowerCase() === 'update dsp' && x.data.id) {
            const dsp = options.dsps.find((d) => d.id === x.data.id);
            x.data.name = (dsp && dsp.name) || 'unknown dsp';
          }

          if (x.event.toLowerCase() === 'update partner' && x.data.id) {
            const userMatch = options.users.find((u) => u.id === x.data.id);
            x.data.name = (userMatch && userMatch.firstName) || 'unknown partner';
          }

          if (Array.isArray(x.data) && x.data.some((d) => d.dspId)) {
            x.data = x.data.map((eventData) => {
              const dsp = options.dsps.find((d) => d.id === eventData.dspId);
              return {
                ...eventData,
                dspName: (dsp && dsp.name) || 'unknown dsp',
              };
            });
          }

          if (Array.isArray(x.data) && x.data.some((d) => d.sspId)) {
            x.data = x.data.map((eventData) => {
              const ssp = options.ssps.find((dsp) => dsp.id === eventData.sspId);
              return {
                ...eventData,
                sspName: (ssp && ssp.name) || 'unknown ssp',
              };
            });
          }

          return {
            createdAt: moment.utc(x.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            event: x.event,
            data: (x.data && JSON.stringify(x.data, null, 2)) || 'n/a',
            ip: x.ip,
            userAgent: x.userAgent,
            userEmail: (user && user.emailAddress) || 'unknown user',
            userId: x.userId,
          };
        })
        .filter(_.identity);

      setEventLogs(eventLogsResponse);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      const usersResponse = await apiGateway.users.getAll();
      const dspsResponse = await apiGateway.dsps.getAll();
      const sspsResponse = await apiGateway.ssps.getAll();
      const eventTypesResponse = await apiGateway.statistics.getEventTypes();

      setUsers(usersResponse);
      setDsps(dspsResponse);
      setSsps(sspsResponse);
      setEventTypes(eventTypesResponse.data);

      await refreshData({
        pageNumber: 1,
        pageSize,
        users: usersResponse,
        dsps: dspsResponse,
        ssps: sspsResponse,
      });
    })();
  }, []);

  const paginationChanged = async (page, pSize) => {
    setPageSize(pSize);
    setCurrentPage(page);

    await refreshData({
      pageNumber: page,
      pageSize: pSize,
      users,
      dsps,
      ssps,
      search: [searchValue, eventTypeValue].filter(_.identity).join(','),
    });
  };

  const search = (searchString) =>
    refreshData({
      pageNumber: 1,
      pageSize: 10,
      users,
      dsps,
      ssps,
      search: searchString,
    });

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onEventTypeSelect = (eventType) => {
    setEventTypeValue(eventType);
  };

  const onFormSubmit = () => search([searchValue, eventTypeValue].filter(_.identity).join(','));

  if (loading) return <Spinner loading />;

  return (
    <Container>
      <Row>
        <Col md={6}>
          <Form onFinish={onFormSubmit}>
            <Form.Item label="Search by ip and userId">
              <Input placeholder="input search text" allowClear onChange={onSearchChange} value={searchValue} />
            </Form.Item>

            <Form.Item label="Event Type">
              <Select style={{ width: 120 }} allowClear onChange={onEventTypeSelect} value={eventTypeValue}>
                {(eventTypes &&
                  eventTypes.length &&
                  eventTypes.map((eventType) => (
                    <Option key={eventType} value={eventType}>
                      {eventType}
                    </Option>
                  ))) ||
                  ''}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col md={12}>
          <Table data={eventLogs} defaultSortBy="createdAt" defaultSortByOrder="desc" />
          <br />
          <Pagination
            total={totalEventLogs}
            onChange={paginationChanged}
            pageSizeOptions={[10, 50, 100, 500]}
            pageSize={pageSize}
            current={currentPage}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default EventLogsPage;
