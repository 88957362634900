import React, { useEffect } from 'react';

import _ from 'lodash';
import { Button, Form, Input, InputNumber, Switch, TreeSelect, Select, Radio } from 'antd';
import iabCategories from '../../constants/iabCategories.json';

const initialValues = {
  isActive: false,
  defaultTmax: 0,
  maxTmax: 0,
  minTmax: 0,
  defaultBidfloor: 0,
  margin: 15,
  revenueSharePercent: 0,
  limitQps: 0,
  dailySpendLimit: 0,
  totalSpendLimit: 0,
  priceFromNurl: false,
  spendCounter: 'i',
};

export default function UpsertSspForm(props) {
  // eslint-disable-next-line react/prop-types
  const { ssp, onFinish, users } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (ssp) {
      // eslint-disable-next-line @typescript-eslint/no-shadow,react/prop-types
      const iabCategories = (ssp.catsForHB || '').split(',').filter(_.identity);

      form.setFieldsValue({
        ..._.pick(ssp, [
          'isActive',
          'partnerId',
          'name',
          'defaultTmax',
          'maxTmax',
          'minTmax',
          'defaultBidfloor',
          'margin',
          'revenueSharePercent',
          'limitQps',
          'dailySpendLimit',
          'totalSpendLimit',
          'priceFromNurl',
          'note',
          'spendCounter',
        ]),
        iabCategories,
      });
    }
  }, [props]);

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      initialValues={initialValues}
    >
      <Form.Item label="Active:" valuePropName="checked" name="isActive">
        <Switch />
      </Form.Item>

      <Form.Item name="partnerId" label="Company" rules={[{ required: true }]}>
        <Select
          /* eslint-disable-next-line react/prop-types */
          options={users.map((u) => ({ label: `[#${u.id}] ${u.firstName}`, value: u.id }))}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>

      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Default Tmax"
        name="defaultTmax"
        extra="This value will be utilized if the SSP bid request lacks a tmax field."
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Max Tmax"
        name="maxTmax"
        extra="This value will be utilized when tmax is higher than this value. The default value is 490ms. Make sure the value is higher than Min Tmax."
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Min Tmax"
        name="minTmax"
        extra="This value will be utilized in min tmax validation, eror will be thrown when tmax is lower than this value. The default value is 130ms. Also, make sure that the value is higher or equal to the DSP Min Tmax."
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Default Bid Floor"
        name="defaultBidfloor"
        extra="This value will be utilized if the SSP bid request lacks a bidfloor field. If this field is set to 0 of lower 0.05, the platform's default bidfloor of 0.05 will be applied instead"
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Margin Percent"
        name="margin"
        help="% that will be added to the bidfloor field. Use this field OR revenue share percent"
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Revenue Share Percent"
        name="revenueSharePercent"
        help="Formula: bidResponsePrice * (sspRevenueSharePercent / 100). Use this field OR margin percent. If DSP Revenue Share Percent is enabled then we will use SSP DSP Revenue Share Percent"
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="QPS limit" name="limitQps">
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Daily Spend Limit" name="dailySpendLimit">
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Total Spend Limit" name="totalSpendLimit">
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="SSP Spend Counter" name="spendCounter">
        <Radio.Group>
          <Radio value="i">Impression</Radio>
          <Radio value="n">Nurl</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="IAB Categories" name="iabCategories">
        <TreeSelect
          treeData={iabCategories}
          treeCheckable
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          style={{ width: '100%', minWidth: '300px' }}
        />
      </Form.Item>

      <Form.Item label="Notes" name="note">
        <Input.TextArea allowClear />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          {ssp ? 'Update' : 'Create'} (General)
        </Button>
      </Form.Item>
    </Form>
  );
}
