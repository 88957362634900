import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import _ from 'lodash';
import moment from 'moment/moment';
import useApiGateway from '../../../apiGateway';

function ResponseTime({ side }) {
  const apiGateway = useApiGateway();

  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: _.uniqBy(
        tableData.map((x) => ({
          text: x.name,
          value: x.name,
        })),
        'value'
      ),
      onFilter: (value, record) => record.name === value,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      filters: _.uniqBy(
        tableData.map((x) => ({
          text: x.id,
          value: x.id,
        })),
        'value'
      ),
      onFilter: (value, record) => record.id === value,
    },
    {
      title: 'Min',
      dataIndex: 'min',
      key: 'min',
      sorter: (a, b) => a.min - b.min,
    },
    {
      title: '10th Percentile',
      dataIndex: 'p10',
      key: 'p10',
      sorter: (a, b) => a.p10 - b.p10,
    },
    {
      title: '15th Percentile',
      dataIndex: 'p15',
      key: 'p15',
      sorter: (a, b) => a.p15 - b.p15,
    },
    {
      title: '25th Percentile',
      dataIndex: 'p25',
      key: 'p25',
      sorter: (a, b) => a.p25 - b.p25,
    },
    {
      title: '50th Percentile',
      dataIndex: 'p50',
      key: 'p50',
      sorter: (a, b) => a.p50 - b.p50,
    },
    {
      title: '75th Percentile',
      dataIndex: 'p75',
      key: 'p75',
      sorter: (a, b) => a.p75 - b.p75,
    },
    {
      title: '90th Percentile',
      dataIndex: 'p90',
      key: 'p90',
      sorter: (a, b) => a.p90 - b.p90,
    },
    {
      title: '95th Percentile',
      dataIndex: 'p95',
      key: 'p95',
      sorter: (a, b) => a.p95 - b.p95,
    },
    {
      title: 'Max',
      dataIndex: 'max',
      key: 'max',
      sorter: (a, b) => a.max - b.max,
    },
    {
      title: 'Total',
      dataIndex: 'totalResponses',
      key: 'totalResponses',
      sorter: (a, b) => a.totalResponses - b.totalResponses,
    },
  ];

  useEffect(() => {
    (async () => {
      const serversInfoResponse = await apiGateway.system.getSystemStatus();

      const result = _.sortBy(
        (serversInfoResponse || [])
          .map((serverInfo) => {
            const responsesTime =
              side === 'ssp'
                ? _.get(serverInfo, 'data.responseTime.sspBidResponse')
                : _.get(serverInfo, 'data.responseTime.dspBidResponseBid');

            if (!responsesTime) return null;

            return Object.entries(responsesTime).map(([key, value]) => ({
              id: key,
              ...value,
              name: serverInfo.name,
              updatedAt: moment.utc().format('MMM Do HH:mm'),
            }));
          })
          .filter(Boolean)
          .flat(),
        'name'
      );

      setTableData(result);
    })();
  }, []);

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table columns={columns} dataSource={tableData} pagination={false} size="small" />
    </div>
  );
}

ResponseTime.propTypes = {
  side: PropTypes.oneOf(['ssp', 'dsp']).isRequired,
};

export default ResponseTime;
