import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { notification, Typography, Divider, Form, Button, Input, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';

import { AntTable, AntButton, AntModal, AntDragAndDrop } from '../../../components';
import { createApiGateway } from '../../../apiGateway';
import getOr from '../../../helpers/getOr';

export default function Lists(props) {
  const apiGateway = createApiGateway();

  const { title: pageTitle } = useParams();

  const [globalLists, setGetGlobalLists] = useState([]);
  const [listId, setListId] = useState(null);
  const [globalListData, setGlobalListData] = useState(null);

  const updateGlobalListsState = async () => {
    const globalListsResponse = await apiGateway.globalLists.getGlobalLists({
      category: pageTitle,
    });

    setGetGlobalLists(globalListsResponse.data || []);
  };

  useEffect(() => {
    (async () => {
      await updateGlobalListsState();
    })();
  }, _.values(props));

  const onIdClick = (id) => {
    setListId(id);
  };

  const deleteGlobalListItem = (title) => {
    const data = (globalListData || []).filter((x) => x.title !== title);
    setGlobalListData(data);
  };

  const deleteGlobalList = async (id) => {
    try {
      await apiGateway.globalLists.deleteGlobalList(id);
      await updateGlobalListsState();
      notification.success({ message: 'Global List successfully delete' });
    } catch (e) {
      notification.error({ message: `Can not delete Global List: ${e.message}` });
    }
  };

  const selectedList = (listId && globalLists.find((x) => x.id === listId)) || null;

  if (selectedList && selectedList.data && (!globalListData || !globalListData.length)) {
    setGlobalListData(selectedList.data.split(',').map((x) => ({ title: x })));
  }

  const tableProps = {
    dataSource: globalLists.map((x) => {
      const data = getOr(x, 'data', '');
      return { ...x, length: data ? data.split(',').length : 0 };
    }),
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        render: (id) => (
          <AntButton type="link" onClick={() => onIdClick(id)}>
            {id}
          </AntButton>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Length',
        dataIndex: 'length',
      },
      {
        title: 'Action',
        dataIndex: '',
        render: (skip, record) => {
          function confirm() {
            deleteGlobalList(record.id);
          }

          return (
            <Popconfirm title="Are you sure delete this List?" onConfirm={confirm} okText="Yes" cancelText="No">
              <AntButton type="text" danger>
                Delete
              </AntButton>
            </Popconfirm>
          );
        },
      },
    ],
    pagination: false,
  };

  const modalProps = {
    visible: Boolean(listId),
    onCancel: () => {
      setListId(null);
      setGlobalListData([]);
    },
    onOk: async () => {
      try {
        const data = {
          data: (globalListData || []).map((x) => x.title).join(','),
        };

        await apiGateway.globalLists.updateGlobalList(listId, data);
        setListId(null);
        setGlobalListData([]);
        await updateGlobalListsState();
        notification.success({ message: 'Global List successfully updated' });
      } catch (e) {
        notification.error({ message: `Can not update Global List: ${e.message}` });
      }
    },
  };

  const modalTableProps = {
    dataSource: globalListData || [],
    columns: [
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Action',
        dataIndex: '',
        render: (skip, record) => (
          <AntButton type="text" danger onClick={() => deleteGlobalListItem(record.title)}>
            Delete
          </AntButton>
        ),
      },
    ],
    scroll: { y: 300 },
    pagination: false,
  };

  const modalDragAndDropProps = {
    name: 'file',
    accept: '.txt',
    multiple: false,
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = function () {
        let data = reader.result
          .split('\n')
          .map(_.trim)
          .map((x) => ({ title: x }));
        data = _.uniqBy([...data, ...(globalListData || [])], 'title');

        setGlobalListData(data);
      };

      reader.onerror = function () {
        notification.error({ message: `File reading error: ${reader.error}` });
      };

      return false;
    },
    supportText: 'Support only .txt files. Data in the file should be separated by enter',
  };

  const createGlobalList = async (values) => {
    try {
      await apiGateway.globalLists.createGlobalList({ ...values, category: pageTitle });
      await updateGlobalListsState();
      notification.success({ message: 'Global List successfully created' });
    } catch (e) {
      notification.error({ message: `Can not create Global List: ${e.message}` });
    }
  };

  return (
    <>
      <AntModal {...modalProps}>
        <Typography.Title level={1}>Existing list data</Typography.Title>
        <AntTable {...modalTableProps} />
        <Divider />
        <Typography.Title level={1}>Upload new list data</Typography.Title>
        <AntDragAndDrop {...modalDragAndDropProps} />
      </AntModal>

      <Container>
        <Row>
          <Col md={12}>
            <Typography.Title>Existing Lists</Typography.Title>
            <AntTable {...tableProps} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col md={6}>
            <Typography.Title>Create New List</Typography.Title>
            <Form onFinish={createGlobalList}>
              <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please input a title!' }]}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
