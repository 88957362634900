import React from 'react';
import { Select as S } from 'antd';

export default function Select(props) {
  return (
    <div>
      <S style={{ width: 120, color: 'black' }} {...props} />
    </div>
  );
}
