import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export default function AntDragAndDrop(props) {
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      {/* eslint-disable-next-line react/prop-types */}
      <p className="ant-upload-hint">{props.supportText}</p>
    </Dragger>
  );
}
