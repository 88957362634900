import React from 'react';

import { Form, Input, Button, notification, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { createApiGateway } from '../../../../../apiGateway';

export default function UpdatePassword() {
  const apiGateway = createApiGateway();
  const userInfo = useSelector((state) => state.login);

  const [form] = Form.useForm();

  const passwordConfirmation = (newPassword, confirmNewPassword) => {
    if (newPassword !== confirmNewPassword) {
      notification.error({ message: 'New Password and New Password Confirmation are not equal!' });
      return false;
    }

    return true;
  };

  const onFinish = async (formFields) => {
    const passwordEnteredRight = passwordConfirmation(formFields.newPassword, formFields.confirmNewPassword);

    if (!passwordEnteredRight) return;

    try {
      await apiGateway.users.updatePassword(formFields.oldPassword, formFields.newPassword, userInfo.userId);
      form.resetFields();
      notification.success({ message: 'Password updated successfully' });
    } catch (e) {
      notification.error({ message: 'Failed to update new password' });
    }
  };

  return (
    <div>
      <Typography.Text strong={true}>Update Password</Typography.Text>
      <div>
        New Password rules:
        <ul>
          <li>min length - 10</li>
          <li>max length - 100</li>
          <li>min 1 uppercase</li>
          <li>min 1 lowercase</li>
          <li>min 1 digit</li>
          <li>min 1 symbol</li>
          <li>min 1 letter</li>
          <li>no spaces</li>
        </ul>
      </div>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: 'Please input your old password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmNewPassword"
          rules={[
            {
              required: true,
              message: 'Please input your new password confirmation!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
