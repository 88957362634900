import querystring from 'querystring';
import Promise from 'bluebird';

export default ({ authorizedGET, authorizedPUT, authorizedPOST, authorizedDELETE }, endpoints) => {
  const getCategories = () => authorizedGET(endpoints.globalLists.categories);

  const getGlobalLists = (queryParams = {}) => {
    const url = `${endpoints.globalLists.root}?${querystring.stringify(queryParams)}`;
    return authorizedGET(url);
  };

  const updateGlobalList = (id, data) => {
    const url = `${endpoints.globalLists.root}/${id}`;
    return authorizedPUT(url, data);
  };

  const createGlobalList = (data) => authorizedPOST(endpoints.globalLists.root, data);

  const deleteGlobalList = (id) => {
    const url = `${endpoints.globalLists.root}/${id}`;
    return authorizedDELETE(url);
  };

  const addGlobalListToCommonList = (listName, globalListId, data) => {
    const url = `${endpoints.lists}/${listName}/globalLists/${globalListId}`;
    return authorizedPOST(url, data);
  };

  const addGlobalListsToCommonList = (listName, dspOrSspId, isDsp, globalListsIds) =>
    Promise.map(
      globalListsIds,
      (globalListId) => addGlobalListToCommonList(listName, globalListId, { dspOrSspId, isDsp }),
      { concurrency: 4 }
    );

  const getGlobalListCommonLists = (queryParams = {}) => {
    const url = `${endpoints.lists}/globalLists?${querystring.stringify(queryParams)}`;
    return authorizedGET(url);
  };

  const deleteGlobalListCommonList = (id) => {
    const url = `${endpoints.lists}/${id}/globalLists`;
    return authorizedDELETE(url);
  };

  const deleteGlobalListsCommonLists = (ids) => Promise.map(ids, deleteGlobalListCommonList, { concurrency: 4 });

  return {
    getCategories,
    getGlobalLists,
    updateGlobalList,
    createGlobalList,
    deleteGlobalList,
    addGlobalListsToCommonList,
    getGlobalListCommonLists,
    deleteGlobalListsCommonLists,
  };
};
