import React from 'react';
import { Typography } from 'antd';

import { Col, Container, Row } from 'react-bootstrap';

import Servers from './Servers';
import ResponseTime from './ResponseTime';

export default function Dashboard() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Typography.Text strong={true}>Nodes Statuses</Typography.Text>
        </Col>
        <Col md={12}>
          <Servers />
        </Col>
        <Col md={12}>
          <br />
          <Typography.Text strong={true}>SSP Bid Response Time</Typography.Text>
        </Col>
        <Col md={12}>
          <ResponseTime side="ssp" />
        </Col>
        <Col md={12}>
          <br />
          <Typography.Text strong={true}>DSP Bid Response Time</Typography.Text>
        </Col>
        <Col md={12}>
          <ResponseTime side="dsp" />
        </Col>
      </Row>
    </Container>
  );
}
