import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { notification } from 'antd';
import { get } from 'lodash';

import { FormInput } from '../../../components';
import Login from '../../../service/user/login';

function LoginPage() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  const submitLogin = async () => {
    try {
      await Login.call({ login, password, otp });
      window.location.reload();
    } catch (e) {
      notification.error({ message: get(e, 'response.data.message') || e.message });
    }
  };

  return (
    <Container>
      <div className="text-center m-5">
        <h1>XChange Admin Panel</h1>
      </div>
      <Row className="d-flex justify-content-center h-100">
        <Col md={4}>
          <Form className="p-3">
            <FormInput
              className="mb-2"
              name="email"
              type="text"
              onChange={({ target: { value } }) => setLogin(value)}
              label="Login"
            />
            <FormInput
              name="password"
              type="password"
              onChange={({ target: { value } }) => setPassword(value)}
              label="Password"
            />
            <br />
            <div>
              <FormInput
                name="otp"
                type="password"
                onChange={({ target: { value } }) => setOtp(value)}
                label="One Time Password (OTP)"
              />
              <i>Enter this only if you configured 2FA</i>
            </div>
            <hr />
            <Button className="w-100" variant="success" onClick={submitLogin}>
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
