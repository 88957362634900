import { authorizedGET, authorizedPOST, authorizedPUT, authorizedDELETE } from '../helpers/requester';
import endpoints from '../constants/endpoints';

import { default as dsps } from './dsps';
import { default as ssps } from './ssps';
import { default as users } from './users';
import { default as statistics } from './statistics';
import { default as globalLists } from './globalLists';
import { default as system } from './system';
import { default as samples } from './samples';
import { createAuctionStatistics } from './auctionStatistics';
import { createWhiteAndBlackLists } from './whiteAndBlackLists';

export type Requester = {
  authorizedGET: <T>(url: string) => Promise<T>;
};

export const createApiGateway = () => {
  const requester = {
    authorizedGET,
    authorizedPOST,
    authorizedPUT,
    authorizedDELETE,
  };

  return {
    dsps: dsps(requester, endpoints),
    ssps: ssps(requester, endpoints),
    users: users(requester, endpoints),
    statistics: statistics(requester, endpoints),
    auctionStatistics: createAuctionStatistics(requester, endpoints),
    whiteAndBlackLists: createWhiteAndBlackLists(requester, endpoints),
    globalLists: globalLists(requester, endpoints),
    system: system(requester, endpoints),
    samples: samples(requester, endpoints),
    ...requester,
  };
};

export default createApiGateway;
