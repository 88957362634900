import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';
import 'react-json-view-lite/dist/index.css';
import './App.css';

import store from './app/store';

import LoginPage from './pages/unauthorized/login';
import DashboardPage from './pages/authorized/dashboard';
import { StatisticsV2Page } from './pages/authorized/statistics-v2';
import FinancesPage from './pages/authorized/finances';
import CompaniesPage from './pages/authorized/companies';
import { DspsV2Page } from './pages/authorized/dsps';
import DSPPage from './pages/authorized/dsp';
import { SspsPage } from './pages/authorized/ssps';
import SSPPage from './pages/authorized/ssp';
import EventLogsPage from './pages/authorized/eventLogs';
import ListsPage from './pages/authorized/lists';
import Settings from './pages/authorized/settings';
import ToolsPage from './pages/authorized/tools';
import UserManagementPage from './pages/authorized/userManagement';
import { AuthLayout } from './layouts/AuthLayout';

const unauthorizedRoutes = [{ path: '/', element: <LoginPage /> }];

const authorizedRoutes = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { path: '/', element: <DashboardPage /> },
      { path: '/statistics-v2', element: <StatisticsV2Page /> },
      { path: '/finances', element: <FinancesPage /> },
      { path: '/companies', element: <CompaniesPage /> },
      { path: '/dsps', element: <DspsV2Page /> },
      { path: '/dsps/:id', element: <DSPPage /> },
      { path: '/ssps', element: <SspsPage /> },
      { path: '/ssps/:id', element: <SSPPage /> },
      { path: '/event-logs', element: <EventLogsPage /> },
      { path: '/lists/:title', element: <ListsPage /> },
      { path: '/settings', element: <Settings /> },
      { path: '/tools', element: <ToolsPage /> },
      { path: '/user-management', element: <UserManagementPage /> },
    ],
  },
];

const router = createBrowserRouter(
  window.localStorage.hasOwnProperty('accessToken') ? authorizedRoutes : unauthorizedRoutes
);

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </Provider>
  );
}

export default App;
