import _ from 'lodash';
import Promise from 'bluebird';
import { authorizedDELETE, authorizedPOST, authorizedPUT } from '../helpers/requester';

export default ({ authorizedGET }, endpoints) => {
  // it will get all users
  // note that the way it's work is not flexible for bit amount of users which always grows
  // TODO: remove hardcoded data fetching

  // options.allowedRoles - list of allowed roles (admin, user, superUser)
  const getAll = async (options = {}) => {
    const limit = 5000;
    const offset = 0;

    const url = `${endpoints.user.root}?limit=${limit}&offset=${offset}`;
    const response = await authorizedGET(url);

    if (_.isArray(options.allowedRoles) && _.size(options.allowedRoles)) {
      return response.filter((x) => options.allowedRoles.includes(x.role));
    }

    return response;
  };

  const getUserByLogin = async (login) => {
    const users = await getAll();
    return users.find((x) => x.login === login) || null;
  };

  const getUserById = (userId) => authorizedGET(`${endpoints.user.root}/${userId}`);

  const getUserInfo = () => authorizedGET(endpoints.user.me);

  const deleteUser = (userId) => authorizedDELETE(`${endpoints.user.root}/${userId}`);

  const updateUser = (user) => authorizedPUT(`${endpoints.user.root}/${user.id}`, user);

  const createUser = (data) => authorizedPOST(endpoints.user.root, data);

  const getUserAccess = (userId) => {
    const url = `${endpoints.user.access}/${userId}`;
    return authorizedGET(url);
  };

  const createUserAccess = (data) => authorizedPOST(endpoints.user.access, data);

  const createUserAccesses = (arr) => Promise.map(arr, createUserAccess, { concurrency: 1 });

  const deleteUserAccess = (id) => authorizedDELETE(`${endpoints.user.access}/${id}`);

  const deleteUserAccesses = (arr) => Promise.map(arr, deleteUserAccess, { concurrency: 4 });

  const configure2fa = (userId) => authorizedPOST(`${endpoints.user.users}/${userId}/2fa`);

  const enable2fa = (userId) => authorizedPUT(`${endpoints.user.users}/${userId}/2fa`);

  const disable2fa = (userId, otp) => authorizedDELETE(`${endpoints.user.users}/${userId}/2fa?otp=${otp}`);

  const updatePassword = (oldPassword, newPassword, userId) => {
    const url = `${endpoints.user.users}/${userId}/password`;

    return authorizedPUT(url, { oldPassword, newPassword });
  };

  return {
    getAll,
    getUserByLogin,
    getUserById,
    getUserInfo,
    deleteUser,
    updateUser,
    createUser,
    getUserAccess,
    createUserAccesses,
    deleteUserAccesses,
    configure2fa,
    disable2fa,
    enable2fa,
    updatePassword,
  };
};
