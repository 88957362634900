const partnerAccessTokenViewOnly = 'googleDataStudio_XX1';

type Options = {
  withToken?: boolean;
};

export function openInNewTab(url: string, options: Options) {
  const { withToken } = options || {};

  const win = window.open(withToken ? `${url}&partnerAccessToken=${partnerAccessTokenViewOnly}` : url, '_blank');

  if (win) {
    win.focus();
  }
}
