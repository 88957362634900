import Joi from '@hapi/joi';

const schema = Joi.object({
  partnerId: Joi.number().integer().min(1).required(),
  name: Joi.string().required(),
  authName: Joi.string().required(),
  authPassword: Joi.string().required(),
  defaultTmax: Joi.number().required(),
  defaultBidfloor: Joi.number().required(),
  margin: Joi.number().required(),
  limitQps: Joi.number().required(),
  spendCounter: Joi.string().required(),
  dailySpendLimit: Joi.number().required(),
  totalSpendLimit: Joi.number().required(),
  isActive: Joi.number().integer().min(0).max(1).required(),
});

export default (obj) => {
  const { error } = schema.validate(obj, { allowUnknown: true });
  if (error) throw error;

  return obj;
};
