import querystring from 'querystring';
import { cloneDeep } from 'lodash';
import { notification } from 'antd';

export default ({ authorizedGET, authorizedPOST }, endpoints) => {
  const getSspDspStatistics = (partnerId, queryParams = {}) => {
    const url = endpoints.statistics.hourly.common.partner(partnerId);

    return authorizedGET(`${url}?${querystring.stringify(queryParams)}`);
  };

  const getEventLogs = (queryParams) => {
    const url = endpoints.statistics.eventLogs();

    return authorizedGET(`${url}?${querystring.stringify(queryParams)}`);
  };

  const getQps = () => authorizedGET(endpoints.statistics.qps);

  const getTotalStatistics = (queryParams) =>
    authorizedGET(`${endpoints.statistics.hourly.totalEndpoint}?${querystring.stringify(queryParams)}`);

  const getEventTypes = () => {
    const url = `${endpoints.statistics.eventLogs()}/types`;

    return authorizedGET(url);
  };

  const getReqOrResStatistics = async (queryParams) => {
    const queryParamsCopy = cloneDeep(queryParams);

    try {
      await authorizedPOST(endpoints.samples.samplesRequest, queryParamsCopy);
    } catch (e) {
      notification.error({
        message: `Failed to fetch sample for US: ${e.message}`,
      });
    }
  };

  return {
    getSspDspStatistics,
    getEventLogs,
    getQps,
    getTotalStatistics,
    getEventTypes,
    getReqOrResStatistics,
  };
};
