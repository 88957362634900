import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { Button, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Select } from '../components';
import globalListCategories from '../constants/globalListCategories.json';

// eslint-disable-next-line react/prop-types
function PageTitle({ title }) {
  return <Typography.Text style={{ wordBreak: 'normal' }}>{title}</Typography.Text>;
}

function Header() {
  const navigate = useNavigate();

  const performLogout = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  const userInfo = useSelector((state) => state.login);

  return (
    <Navbar>
      <Navbar.Brand href="/">
        <PageTitle title="XChange" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <NavLink to="/statistics-v2" className="nav-link" exact>
          <PageTitle title="Statistics" />
        </NavLink>
        <NavLink to="/finances" className="nav-link" exact>
          <PageTitle title="Finances" />
        </NavLink>
        <NavLink to="/companies" className="nav-link" exact>
          <PageTitle title="Companies" />
        </NavLink>
        <NavLink to="/dsps" className="nav-link" exact>
          <PageTitle title="DSPs" />
        </NavLink>
        <NavLink to="/ssps" className="nav-link" exact>
          <PageTitle title="SSPs" />
        </NavLink>

        <Select
          options={globalListCategories}
          bordered={false}
          defaultActiveFirstOption={false}
          value={'Lists'}
          onSelect={(selectedPage) => {
            navigate(`/lists/${selectedPage}`);
            navigate(0);
          }}
        />

        <NavLink to="/tools" className="nav-link" exact>
          <PageTitle title="Tools" />
        </NavLink>
        <NavLink to="/event-logs" className="nav-link" exact>
          <PageTitle title="Event Logs" />
        </NavLink>
        <NavLink to="/settings" className="nav-link" exact>
          <PageTitle title="Settings" />
        </NavLink>
        {userInfo && (userInfo.role === 'admin' || userInfo.role === 'localAdmin') && (
          <NavLink to="/user-management" className="nav-link" exact>
            <PageTitle title="User Management" />
          </NavLink>
        )}
      </Navbar.Collapse>

      <Button type="link" danger={true} onClick={performLogout}>
        Log Out
      </Button>
    </Navbar>
  );
}

export default Header;
