export const API_URL = process.env.REACT_APP_API_URL;
export const EXCHANGE_NODE_BASE_URL = process.env.REACT_APP_EXCHANGE_NODE_BASE_URL;

if (!API_URL) {
  throw new Error('REACT_APP_API_URL is required');
}

if (!EXCHANGE_NODE_BASE_URL) {
  throw new Error('REACT_APP_EXCHANGE_NODE_BASE_URL is required');
}
