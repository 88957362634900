import querystring from 'querystring';

export default ({ authorizedGET }, endpoints) => {
  // it will fetch all existing DSPs from API
  // note that it's using hardcoded limit and it's mean that at one point
  // of time it won't return ALL dsps
  // TODO: remove hardcoded data fetching
  const getAll = async () => {
    const queryParams = {
      limit: 5000,
      offset: 0,
    };

    return authorizedGET(`${endpoints.dsp.list}?${querystring.stringify(queryParams)}`);
  };

  const getById = async (id) => authorizedGET(`${endpoints.dsp.list}/${id}`);

  return {
    getAll,
    getById,
  };
};
