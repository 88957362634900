import React, { useEffect, useState } from 'react';
import { Table, Modal, Button } from 'antd';
import { JsonView } from 'react-json-view-lite';
import _ from 'lodash';
import moment from 'moment';

import useApiGateway from '../../../../apiGateway';
import toReadableNumber from '../../../../helpers/toReadableNumber';
import { Status } from '../../../../components/Status';

function sumQpsFields(data) {
  const result = {};

  for (const field in data) {
    result[field] = Object.values(data[field]).reduce((acc, cur) => acc + parseInt(cur, 10), 0);
  }

  return result;
}

const ACTIVE = 'Active';
const INACTIVE = 'Inactive';
const MAINTENANCE = 'Maintenance';

export default function Servers() {
  const apiGateway = useApiGateway();

  const [tableData, setTableData] = useState([]);
  const [serversInfo, setServersInfo] = useState(null);
  const [jsonToShow, setJsonToShow] = useState(null);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
      await getSystemNodesStatus();
    })();
  }, []);

  const sortServersInfo = (servers) => {
    const result = [];

    servers
      .map((x) => ({
        index: Number(x.name.replace(/\D/g, '')),
        group: x.name.replace(/\d/g, ''),
        ...x,
      }))
      .reduce((accumulator, current) => {
        const group = accumulator.find((x) => x[0] === current.group);

        if (!group) {
          return [...accumulator, [current.group, [current]]];
        }

        group[1].push(current);

        return accumulator;
      }, [])
      .forEach((group) => {
        _.sortBy(group[1], 'index').forEach((x) => {
          result.push(x);
        });
      });

    return result;
  };

  const getSystemNodesStatus = async () => {
    const serversInfoResponse = await apiGateway.system.getSystemStatus();

    const qpsData = sortServersInfo(
      (serversInfoResponse || [])
        .map((serverInfo) => {
          const qps = _.get(serverInfo, 'data.qps');

          const sumQps = sumQpsFields(qps);

          return {
            name: serverInfo.name,
            ip: serverInfo.ip,
            // eslint-disable-next-line no-nested-ternary
            status: serverInfo.status ? (_.get(serverInfo, 'data.maintenance') ? MAINTENANCE : ACTIVE) : INACTIVE,
            updatedAt: moment.utc().format('MMM Do HH:mm'),
            sspBidRequest: {
              count: Math.ceil(sumQps.sspBidRequest) || 0,
              qps: Math.ceil((sumQps.sspBidRequest || 0) / 60),
            },
            sspResponse: {
              count: Math.ceil(sumQps.sspResponse) || 0,
              qps: Math.ceil((sumQps.sspResponse || 0) / 60),
            },
            sspBidResponse: {
              count: Math.ceil(sumQps.sspBidResponse) || 0,
              qps: Math.ceil((sumQps.sspBidResponse || 0) / 60),
            },
            dspBidRequest: {
              count: Math.ceil(sumQps.dspBidRequest) || 0,
              qps: Math.ceil((sumQps.dspBidRequest || 0) / 60),
            },
            dspResponse: {
              count: Math.ceil(sumQps.dspResponse) || 0,
              qps: Math.ceil((sumQps.dspResponse || 0) / 60),
            },
            dspBidResponse: {
              count: Math.ceil(sumQps.dspBidResponse) || 0,
              qps: Math.ceil((sumQps.dspBidResponse || 0) / 60),
            },
            totalQPS:
              Math.ceil((sumQps.sspBidRequest + sumQps.sspResponse + sumQps.dspBidRequest + sumQps.dspResponse) / 60) ||
              0,
          };
        })
        .filter(_.identity)
    );

    setServersInfo(serversInfoResponse);
    setTableData(qpsData);
  };

  const onServerSelect = (ip) => {
    setJsonToShow(serversInfo.find((server) => server.ip === ip));
  };

  const clearJsonToShow = () => {
    setJsonToShow(null);
  };

  const getJsonWindow = () => (
    <Modal
      visible
      title="JSON Object"
      width="100%"
      bodyStyle={{ overflowX: 'scroll' }}
      style={{ top: 20 }}
      onCancel={clearJsonToShow}
      onOk={clearJsonToShow}
    >
      <JsonView data={jsonToShow} />
    </Modal>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Button type="link" onClick={() => onServerSelect(record.ip)}>
          {name}
        </Button>
      ),
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (label) => {
        let status;

        if (label === INACTIVE) {
          status = 'error';
        } else if (label === MAINTENANCE) {
          status = 'warning';
        } else {
          status = 'success';
        }

        return <Status status={status} label={label} />;
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'SSP Bid Requests QPS',
      dataIndex: 'sspBidRequest',
      key: 'sspBidRequest',
      render: (x) => `${toReadableNumber(x.qps)}`,
    },
    {
      title: 'SSP No Bid Responses QPS',
      dataIndex: 'sspResponse',
      key: 'sspResponse',
      render: (x) => `${toReadableNumber(x.qps)}`,
    },
    {
      title: 'SSP Bid Responses QPS',
      dataIndex: 'sspBidResponse',
      key: 'sspBidResponse',
      render: (x) => (
        <div>
          {toReadableNumber(x.qps)}
          {/* <br /> */}
          {/* <span */}
          {/*  // eslint-disable-next-line no-nested-ternary */}
          {/*  style={{ color: x.mean < 250 ? 'black' : x.mean >= 250 && x.mean < 400 ? 'orange' : 'red' }} */}
          {/* > */}
          {/*  {toReadableNumber(x.mean)} */}
          {/*  ms */}
          {/* </span> */}
        </div>
      ),
    },
    {
      title: 'DSP Bid Requests QPS',
      dataIndex: 'dspBidRequest',
      key: 'dspBidRequest',
      render: (x) => `${toReadableNumber(x.qps)}`,
    },
    {
      title: 'DSP No Bid Responses QPS',
      dataIndex: 'dspResponse',
      key: 'dspResponse',
      render: (x) => `${toReadableNumber(x.qps)}`,
    },
    {
      title: 'DSP Bid Responses',
      dataIndex: 'dspBidResponse',
      key: 'dspBidResponse',
      render: (x) => (
        <div>
          {toReadableNumber(x.qps)}
          {/* <br /> */}
          {/* <span */}
          {/*  // eslint-disable-next-line no-nested-ternary */}
          {/*  style={{ color: x.mean < 250 ? 'black' : x.mean >= 250 && x.mean < 400 ? 'orange' : 'red' }} */}
          {/* > */}
          {/*  {toReadableNumber(x.mean)} */}
          {/*  ms ({x.meanPercent} */}
          {/*  %) */}
          {/* </span> */}
        </div>
      ),
    },
    {
      title: 'Total QPS',
      dataIndex: 'totalQPS',
      key: 'totalQPS',
      render: (x) => toReadableNumber(x),
    },
  ];

  return (
    <div style={{ overflowX: 'scroll' }}>
      {jsonToShow ? getJsonWindow() : ''}

      <Table columns={columns} dataSource={tableData} pagination={false} size="small" />
    </div>
  );
}
