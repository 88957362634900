import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export default function NewSspButton() {
  return (
    <Link to="/ssps/new">
      <Button>Create new SSP</Button>
    </Link>
  );
}
