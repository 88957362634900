import Joi from '@hapi/joi';

const schema = Joi.object({
  partnerId: Joi.number().integer().min(1).required().error(new Error('Please, select a company')),
  name: Joi.string().required(),
  host: Joi.string().required(),
  port: Joi.number().integer().min(0).required(),
  path: Joi.string().required(),
  useBanner: Joi.number().integer().min(0).max(1).required(),
  useNative: Joi.number().integer().min(0).max(1).required(),
  useVideo: Joi.number().integer().min(0).max(1).required(),
  usePop: Joi.number().integer().min(0).max(1).required(),
  mobileWeb: Joi.number().integer().min(0).max(1).required(),
  inApp: Joi.number().integer().min(0).max(1).required(),
  desktop: Joi.number().integer().min(0).max(1).required(),
  minTmax: Joi.number().min(0).required(),
  dailySpendLimit: Joi.number().min(0).required(),
  totalSpendLimit: Joi.number().min(0).required(),
  maxBidfloor: Joi.number().min(0).required(),
  videoStartTracker: Joi.number().integer().min(0).max(1).required(),
  secure: Joi.number().integer().min(0).max(1).required(),
  margin: Joi.number().min(0).required(),
  includeExtraMargin: Joi.number().integer().min(0).max(1).required(),
  extraMargin: Joi.when('includeExtraMargin', {
    is: 1,
    then: Joi.number().min(0),
    otherwise: Joi.number().min(0),
  }).required(),
  limitQps: Joi.number().min(0).required(),
  isActive: Joi.number().integer().min(0).max(1).required(),
  isBlocked: Joi.number().integer().min(0).max(1).required(),
});

export default (obj) => {
  const { error } = schema.validate(obj, { allowUnknown: true });
  if (error) throw error;

  return obj;
};
