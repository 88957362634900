import type { Column } from '../index';

type ColumnGroup = {
  title: string;
  children: Column[];
};

type Result = (ColumnGroup | Column)[];

function removePrefix(column: Column, prefix: string): Column {
  const result = column.title.replace(prefix, '').trim();

  return result.length ? { ...column, title: result } : column;
}

export function groupColumns(columns: Column[]): Result {
  const groups = ['SSP', 'DSP'];

  return columns.reduce<Result>((acc, column) => {
    const group = groups.find((x) => column.title.includes(x));

    if (group) {
      const existingGroup = acc.find((item) => item.title === group);

      if (existingGroup) {
        // @ts-expect-error - children available on ColumnGroup
        existingGroup.children.push(removePrefix(column, group));
      } else {
        acc.push({ title: group, children: [removePrefix(column, group)] });
      }
    } else {
      acc.push(column);
    }

    return acc;
  }, []);
}
